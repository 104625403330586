@import "./mixins.scss";
@import "./variables.scss";
@import "./text.scss";

/* Description:
Here we write changes that we did for MaterialUi stuff 
add "mu_" before every name

IMPORTANT
Add &:global(class_name) of a element to match its classes, so we can change their styles

If not, our styles will be applied after elements so they wont work
*/

.mu_btnOutlinedPrimary {
  &:global(.MuiButton-outlinedPrimary) {
    border-color: var(--mu_login_br);
    color: var(--mu_login_color);

    border-radius: 8px;

    font-family: inherit;
    font-weight: 500;

    &:hover {
      border-color: var(--mu_login_br);
    }
  }
}

.mu_btnOutlinedGoogle {
  &:global(.MuiButton-outlinedPrimary) {
    border-color: var(--mu_media_br);
    color: var(--mu_media_color);

    border-radius: 8px;

    font-family: inherit;
    font-weight: 500;

    &:hover {
      border-color: var(--mu_media_br);
    }
  }
}

.mu_btnOutlinedFacebook {
  &:global(.MuiButton-outlinedPrimary) {
    border-color: var(--mu_media_br);
    color: var(--mu_media_color);

    border-radius: 8px;

    font-family: inherit;
    font-weight: 500;

    &:hover {
      border-color: var(--mu_media_br);
      color: #316ff6;
    }
  }
}

.mu_btnContainedPrimary {
  &:global(.MuiButton-containedPrimary) {
    background-color: var(--mu_signin_bg);
    color: var(--mu_signin_color);

    border-radius: 8px;

    font-family: inherit;
    font-weight: 500;

    &:hover {
      background-color: var(--mu_signin_bg);
    }
  }
}

// Email in Login page (Fonts needed some help)
.mu_TextFiledInput {
  &:global(.MuiFormControl-root) {
    font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI",
      Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue",
      sans-serif;

    div {
      font-family: inherit;
    }

    label {
      font-family: inherit;
    }

    input {
      font-family: inherit;
    }
  }
}

// Snackbars
.SnackbarAlert {
  &:global(.MuiPaper-root) {
    background-color: var(--snackbar_bg);
    color: var(--snackbar_color);

    // Hide snackbar icon
    div {
      &:nth-child(1) {
        display: none;
      }
    }
  }
}

.mu_SelectBox {
  &:global(.MuiInputBase-root) {
    min-width: 200px;

    border-radius: 4px;

    font-family: inherit;
    font-weight: 400;

    font-size: 0.9rem;

    fieldset {
      border: 1px solid var(--box_border_color) !important;
    }

    @include tablet() {
      min-width: 120px;
    }

    @include mobile() {
      min-width: 100px;
    }
  }
}

.mu_timeHourClock {
  &:global(.MuiFormControl-root) {
    outline: none;
    // overflow: hidden;

    input {
      //   max-width: 60px;

      font-family: "Poppins", system-ui, "sans-serif";

      border: 0;
      border-radius: calc($row_border_radius * 0.5);

      //   padding: 0;
      padding: $button_default_padding;
    }

    div {
      height: unset;
      outline: none;
      width: unset;

      // border: 1px solid transparent;

      background-color: var(--input_default_bg);
      color: var(--input_default_color);

      align-items: center;

      &:focus-within {
        border: 1px solid var(--primary_color);
      }

      div {
        margin: 0;
        border: 0;

        display: flex;
        justify-content: flex-end;

        &:hover {
          border: unset;
        }

        &:focus-within {
          border: unset;
        }
      }

      padding: 0;

      button {
        border-radius: $input_default_border_radius;
        margin-right: 2px;

        @include tablet {
          padding: $button_small_padding;
        }

        svg {
          width: 16px;
          height: 16px;
        }
      }
    }

    fieldset {
      border: 0;
      border-color: var(--box_border_color);
    }
  }
}
