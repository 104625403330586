@import "../../../styles/mixins.scss";
@import "../../../styles/variables.scss";

.JumpingPopup_Container {
    width: 100%;
    min-height: 20px;
    top: -100px; // Initial position
    left: 0;
    position: fixed;
    padding: 1rem 1.5rem;
    z-index: 400;
    display: flex;
    flex-wrap: nowrap;
    flex-direction: column;
    gap: 1rem;
    align-items: flex-end;
    justify-content: center;
}

.slideDown {
    animation: slideDown 400ms forwards ease-in-out;
}

.slideUp {
    animation: slideUp 400ms forwards ease-in-out;
}

@keyframes slideDown {
    0% {
        top: calc(-100px); // Start from the initial position
    }

    100% {
        top: calc($dashboard_navbar_height); // End at the final position
    }
}

@keyframes slideUp {
    0% {
        top: calc($dashboard_navbar_height); // Start from the current position
    }

    100% {
        top: -100px; // End at the target position
    }
}

.JumpingPopup {
    min-width: 200px;
    max-width: max-content;
    min-height: 36px;
    padding: 0.5rem 1rem;
    border-radius: 50px;
    background-color: var(--error_color);
    color: var(--default_white);

    display: flex;
    flex-direction: row;
    align-items: center;

    @include mobile() {
        min-width: 100%;
        max-width: unset;
    }
}