@import "../../styles/mixins.scss";
@import "../../styles/variables.scss";

.DashboardDataNotFound {
    width: 100%;

    // These caluclations are neccessary because of how Our layout (parent) handles height (min-height) 
    // Because of that, we have to use specific height like 100vh and decrese it by paddings added in layout 

    // Important, if we change something in Layout.scss (paddings) we have to recalculate them here
    min-height: calc(100vh - $dashboard_navbar_height - 8rem);


    @include tablet() {
        min-height: calc(100vh - $dashboard_navbar_height - 4rem);
    }

    @include mobile() {
        min-height: calc(100vh - $dashboard_navbar_height - 4rem);
    }


    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1.5rem;

    .DashboardDataNotFoundAdditionalText {
        width: 100%;
        max-width: 400px;
        min-height: 50px;

        margin: 1rem 0;

        background-color: var(--default_white);

        border-radius: calc($row_border_radius * 0.5);
        border: 1px solid var(--box_border_color);
        padding: 1rem;

        color: var(--select_box_border_color);
    }
}