@import "./mixins.scss";
@import "./variables.scss";

// All styles for texts 
// Mobile 12 - 16px
// Tablet 15 - 19px
// Desktop 16 - 20px

// 2 rem = 32 px 
// 1.5 rem = 24px
// 1.25 rem = 20px
// 1 rem = 16 px
// 0.75 rem = 12px
// 0.5 rem = 8 px

// Font weight
$default_font_weight: 400;
$bold_font_weight: 500;
$ultra_bold_font_weight: 600;
$light_font_weight: 300;

// Font size
$default_font_title_section_size: 32px;
$default_font_big_size: 24px;
$default_font_size: 16px;
$default_font_small_size: 14px;
$default_font_smaller_size: 12px;
$default_font_micro_size: 10px; // Used in tags

.SectionTitleText {
    font-size: $default_font_title_section_size;
    font-weight: $ultra_bold_font_weight;
    color: var(--default_text_color);

    white-space: nowrap;

    @include tablet() {
        font-size: $default_font_big_size;
    }

    @include mobile() {
        font-size: $default_font_size;
    }
}

.DefaultText {
    font-size: $default_font_size;
    color: var(--default_text_color);
    font-weight: $default_font_weight;

    b {
        font-weight: $bold_font_weight;
    }

    @include tablet() {
        font-size: $default_font_small_size;
    }

    // @include mobile() {
    //     font-size: $default_font_smaller_size;
    // }
}

.BigText {
    font-size: $default_font_big_size;
    color: var(--default_text_color);
    font-weight: $default_font_weight;

    b {
        font-weight: $bold_font_weight;
    }

    @include tablet() {
        font-size: $default_font_size;
    }

    @include mobile() {
        font-size: $default_font_small_size;
    }
}

.SmallText {
    font-size: $default_font_small_size;
    font-weight: $default_font_weight;
    color: var(--default_text_color);

    @include tablet() {
        font-size: $default_font_smaller_size;
    }

    // @include mobile() {
    //     font-size: $default_font_micro_size;
    // }
}

.SmallerText {
    font-size: $default_font_smaller_size;
    font-weight: $default_font_weight;
    color: var(--default_text_color);

    @include tablet() {
        font-size: $default_font_micro_size;
    }
}

.MicroText {
    font-size: $default_font_micro_size;
    font-weight: $default_font_weight;
    color: var(--default_text_color);

    @include tablet() {
        font-size: $default_font_micro_size;
    }
}

.LoadMoreText {
    font-size: $default_font_small_size;
    color: var(--primary_color);
    width: 100%;
    text-align: center;

    cursor: pointer;

    &:hover {
        text-decoration: underline;
    }
}

.DataBoxValueText {
    font-weight: 600;
    font-size: $default_font_title_section_size;
    color: var(--default_text_color);

    @include tablet() {
        font-size: $default_font_big_size;
    }
}

.BoxUserTitle {
    width: max-content;
    padding: 0.5rem 1rem;

    font-size: $default_font_small_size;

    // border: 1px solid var(--box_border_color);
    background: var(--premium_gradient);
    border-radius: calc($row_border_radius);
    white-space: nowrap;

    user-select: none;

    color: var(--white);
}

// addons
.text-highlighted-warrning {
    padding: 0.25rem 0.5rem;
    background-color: var(--layout_content_background);
    border-radius: calc($row_border_radius * 0.5);
    user-select: none;
    white-space: nowrap;
}


// Additional styles
.text-capitalize {

    &::first-letter {
        text-transform: uppercase;
    }
}

.text_primary {
    color: var(--primary_color);
}

.text_white {
    color: var(--white)
}

.text_black {
    color: var(--black)
}

.text_gradient_start_bg {
    background-color: var(--blue_gradient_start);
}

.text_overflow_hidden {
    overflow-x: hidden;
    white-space: nowrap;
}

.text_underline {
    text-decoration: underline;
}

.text-gray {
    color: var(--default_text_color_gray);
}

.text_gray_on_layout {
    color: var(--default_text_gray_on_layout);
}

.text_premium {
    color: var(--premium_color);
}

.text_success {
    color: var(--success_color);
}

.text_light_bold {
    font-weight: 400;
}

.text-bold {
    font-weight: $bold_font_weight;
}

.text_ultra_bold {
    font-weight: 700;
}

.text_light {
    font-weight: $light_font_weight;
}

.text-light {
    font-weight: $light_font_weight;
}

.text-italic {
    font-style: italic;
}

.text_no_wrap {
    white-space: nowrap;
}

.text_error {
    color: var(--error_color);
}

.text_left_error_border {
    border-left: 4px solid var(--error_color);
    padding: 2px;
    padding-left: 10px;
}

.text_clickable_in_menu {

    padding: 0.5rem 1rem;
    border-radius: $button_border_radius;

    cursor: pointer;

    &:hover {
        background-color: var(--layout_content_background);
    }

    &.text_in_menu_active {
        
        background-color: var(--layout_content_background);
    }
}

.as_link {
    cursor: pointer;
    border-bottom: 1px solid transparent;

    color: var(--default_text_color);

    max-width: min-content;

    padding-top: 4px;
    line-height: 1;

    overflow: hidden;

    &:hover, a {
        border-bottom: 1px solid var(--default_text_color_gray);
        color: var(--default_text_color);
    }

    &.linkPrimary {
        color: var(--primary_color);

        &:hover {
            border-bottom: 1px solid var(--primary_color);
        }
    }
}

.as_link_new {
    cursor: pointer;
    border-bottom: 1px solid transparent;

    max-width: min-content;

    overflow: hidden;

    &:hover {
        border-bottom: 1px solid var(--default_text_color_gray);
    }

    &.linkPrimary {
        color: var(--primary_color);

        &:hover {
            border-bottom: 1px solid var(--primary_color);
        }
    }
}

.as_link-danger {
    cursor: pointer;
    border-bottom: 1px solid transparent;

    padding-top: 4px;

    overflow: hidden;

    &:hover {
        color: var(--error_color);
        border-bottom: 1px solid var(--error_color);
    }
}

.text_primary_bg {
    padding: 4px;
    background-color: var(--primary_color);
    border-radius: 4px;
    color: var(--white);
}

.text_gray_bg {
    padding: 4px;
    background-color: var(--layout_content_background);
    border-radius: 4px;
    color: var(--default_black);

    &.activity_added {
        background-color: var(--layout_content_background);
    }

    &.activity_edited {
        background-color: var(--layout_content_background);
    }

    &.activity_deleted {
        background-color: var(--error_background);
        color: var(--error_color)
    }
}