@import "../../../styles/mixins.scss";
@import "../../../styles/variables.scss";
@import "../../../styles/text.scss";

.ButtonNeutral,
.ButtonNeutralToDanger,
.ButtonNeutralToLightPrimary,
.ButtonPrimaryErrorFill,
.ButtonPrimaryFill,
.ButtonPrimaryOutlined,
.ButtonPrimaryPremiumFill,
.ButtonNeutralToGray,
.ButtonPrimaryWhiteOutline {
  white-space: nowrap;
  min-width: max-content;

  border: 0;
  border-radius: $button_border_radius;

  padding: $button_default_padding;

  font-weight: $button_bold;

  user-select: none;

  font-size: $default_font_small_size;

  // display: flex;
  // align-items: center;
  // justify-content: center;
  // gap: 0.25rem;

  @include tablet() {
    padding: $button_small_padding;
    font-size: $default_font_smaller_size;
  }

  &[disabled] {
    opacity: 0.5;
    cursor: unset;
  }

  &.small {
    padding: $button_small_padding;
    font-size: $default_font_smaller_size;
  }

  &.roundedBorder {
    border-radius: 50px;
  }

  &.text_with_icon {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.25rem;
  }

  // When we have only icon inside btn
  &.centerAllContent {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.ButtonPrimaryFill {
  background-color: var(--primary_color);

  color: var(--button_default_color);

  &:not([disabled]):hover {
    background-color: var(--on_primary_hover);
  }
}

.ButtonPrimaryPremiumFill {
  background: var(--premium_gradient);

  color: var(--white);

  &:not([disabled]):hover {
    background: var(--premium_gradient_hover);
  }
}

.ButtonPrimaryWhiteOutline {
  background: transparent;

  color: var(--default_black);
  border: 1px solid var(--default_black);

  &:not([disabled]):hover {
    color: var(--default_white);
    background: var(--default_black);
  }
}

.ButtonPrimaryErrorFill {
  background-color: var(--button_error_bg);

  font-weight: $button_bold_500;

  color: var(--white);

  &:not([disabled]):hover {
    background-color: var(--button_error_bg_hover);
  }
}

.ButtonPrimaryOutlined {
  border: 1px solid var(--primary_color);
  background-color: var(--defaul_white);

  color: var(--primary_color);

  &:not([disabled]):hover {
    border-color: var(--on_primary_hover);
    color: var(--on_primary_hover);
  }
}

.ButtonNeutralToDanger {
  font-weight: $bold_font_weight;

  background-color: var(--error_background);

  color: var(--error_color);

  display: flex;
  align-items: center;

  // border: 1px solid var(--box_border_color);

  svg {
    width: $svg_medium_width;
    height: $svg_medium_height;
    stroke-width: 1.5px;

    path {
      stroke: var(--error_color);
    }
  }

  @include tablet() {
    svg {
      width: $svg_small_width;
      height: $svg_small_height;
    }
  }

  &:not([disabled]):hover {
    /* Apply hover styles only when the element is not disabled */
    background-color: var(--button_error_bg_hover);
    color: var(--white);
    // border: 1px solid var(--error_color);

    svg {
      path {
        stroke: var(--white);
      }
    }
  }

  &.withBorder {
    border: 1px solid var(--box_border_color);

    &:hover {
      border-color: var(--button_error_bg_hover);
    }
  }
}

// First used as Back to clients
.ButtonNeutral {
  background-color: var(--button_neutral_bg);
  // border: 1px solid var(--box_border_color);

  font-size: $default_font_small_size;

  color: var(--button_neutral_color);

  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    width: $svg_small_height;
    height: $svg_small_height;

    margin-right: 0.5rem;
  }

  &:not([disabled]):hover {
    background-color: var(--button_neutral_bg_hover);
  }

  &.isActive {
    background-color: var(--button_neutral_bg_hover);
  }
}

.ButtonNeutralToLightPrimary {
  // border: 1px solid var(--box_border_color);

  background-color: var(--button_neutral_bg);

  color: var(--button_neutral_color);

  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    width: $svg_medium_width;
    height: $svg_medium_height;

    margin-right: 0.5rem;
  }

  @include tablet() {
    svg {
      width: $svg_small_width;
      height: $svg_small_height;
    }
  }

  &:not([disabled]):hover {
    background-color: var(--button_neutral_bg_hover_stronger);
    color: var(--button_neutral_color);
  }

  &.isActive {
    background-color: var(--button_neutral_bg_active);
    color: var(--default_text_white);

    &:hover {
      background-color: var(--button_neutral_bg_active);
      color: var(--default_text_white);
      cursor: unset;
    }
  }

  &.btnSmall {
    padding: 0.5rem 0.75rem;
    font-weight: 400;
  }

  &.btnBorder {
    border: 1px solid var(--box_border_color);
  }
}

.ButtonNeutralToGray {
  background-color: var(--box_default_bg);
  color: var(--default_black);

  &:hover {
    background-color: var(--box_on_default_bg);
  }
}

// Needed in analytics.js. It is lighter version of button primary
.ButtonPrimaryStyle {
  background-color: var(--primary_color);
  color: var(--button_default_color);

  &:not([disabled]):hover {
    background-color: var(--on_primary_hover);
    color: var(--button_default_color);
  }

  &[disabled] {
    opacity: 0.5;
    cursor: unset;
  }
}

.ToggleButton {
  height: $input_height;
  display: flex;
  align-items: center;
  justify-content: center;

  .switch {
    cursor: pointer;
    position: relative;
    display: inline-block;
    font-size: 10px;
    /* Increase font-size to scale the switch */
    height: 1.5em;
    /* Adjust the height */
    width: 3em;
    /* Adjust the width */
    background: var(--select_box_border_color);
    /* Variable for background color */
    border-radius: 2em;
    /* Adjust the border-radius */

    div {
      position: absolute;
      top: -0.15em;
      /* Adjust top position for centering */
      left: -0.15em;
      /* Adjust left position for centering */
      height: 1.7em;
      /* Adjust the height of the inner circle */
      width: 1.7em;
      /* Adjust the width of the inner circle */
      border-radius: 50%;
      background-color: #fff;
      box-shadow: 0 0.1em 0.3em rgba(0, 0, 0, 0.5);
      transition: all 150ms;
    }

    input {
      opacity: 0;
      width: 100%;
      height: 100%;
      margin: 0;
      position: absolute;
      cursor: pointer;
    }

    input:checked + div {
      transform: translate3d(calc(100%), 0, 0);
      /* Adjust the translate value */
    }
  }

  &.ToggleChecked {
    .switch {
      background-color: var(--on_primary_hover);
      /* Variable for success color */
    }
  }

  &.ToggleUnChecked {
    .switch {
      background-color: var(--layout_content_background);
      /* Variable for error color */
    }
  }
}

// ADONS
.BtnBig {
  font-size: $default_font_big_size;
  padding: 0.75em;

  @include tablet() {
    font-size: $default_font_size;
  }
}

.BtnFullWidth {
  width: 100%;
}

.BtnShadow {
  box-shadow: 0px 2px 4px rgba(34, 34, 34, 0.5);
}
