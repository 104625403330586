@import "./mixins.scss";
@import "./variables.scss";


.myHr {
    margin: 0;
    padding: 0;
    min-height: 0.5px;
    max-height: 0.5px;
    border-radius: 50px;
    background-color: var(--box_border_color)
}

// === APEX CHARTS ===
// We want to override some styles because they look wrong 

#chart {
    background-color: transparent !important;
    div {
        svg {
            background-color: var(--box_default_bg) !important;
        }
    }
}

.apexcharts-tooltip span{
    color: var(--default_black);
}


// Fav icon
.StarIcon {

    width: 20px;
    height: 20px;

    border-radius: 50px;

    path {
        stroke: var(--icons_stroke_color);
    }

    &.StarIconFilled {
        path {
            stroke: var(--star_stroke);
            fill: var(--star_fill);
        }
    }

    &.StarIconHover {
        &:hover {
            cursor: pointer;
            background-color: var(--layout_content_background)
        }

    }
}

.HideOnMobile {

    @include mobile() {
        display: none;
    }
}

.HideOnTablet {

    @include tablet() {
        display: none;
    }
}

.HideOnBigTablet {

    @include bigTablet() {
        display: none;
    }
}

// Scrollbar classes
.HideScrollbar {
    // Hide scrollbar
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;

    /* Firefox */
    &::-webkit-scrollbar {
        display: none;
    }
}

.HideScrollbarBigTablet {
    @include bigTablet() {
        // Hide scrollbar
        -ms-overflow-style: none;
        /* IE and Edge */
        scrollbar-width: none;

        /* Firefox */
        &::-webkit-scrollbar {
            display: none;
        }
    }
}

.HideScrollbarMobile {
    @include mobile() {
        // Hide scrollbar
        -ms-overflow-style: none;
        /* IE and Edge */
        scrollbar-width: none;

        /* Firefox */
        &::-webkit-scrollbar {
            display: none;
        }
    }
}

// ## Buttons ##  

// Firstly used in NavbarHero
// Controlls navbar and footer links (almost all) 
.navbarLink {
    border-radius: 8px;
    cursor: pointer;

    // transition: all 200ms;

    &:hover {
        background-color: var(--default_white);
        color: var(--default_black);
    }

    @include bigTablet {
        border-radius: 0px;
    }
}

// Here we have all styles shared in Home page '/' Hero, Features etc.. 
.BoxTitle {
    font-size: 1.2rem;
    font-weight: 500;
    text-align: center;
}

.BoxDescription {
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.6;
    color: var(--font_primary_gray);

    @include bigTablet() {
        font-size: 0.9rem;
    }

    @include mobile() {
        font-size: 0.85rem;
    }

    b {
        font-weight: 500;
        color: var(--primary_color);
    }
}

// Features title, about us title ... 
.HomeSectionTitle {
    font-size: 2rem;
    font-weight: 600;
    color: var(--section_title_primary);

    @include bigTablet() {
        font-size: 1.5rem;
    }

    @include mobile() {
        font-size: 1.25rem;
    }
}

// Loading animation 4 DOTS 
.loading_animation {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    display: block;
    position: relative;
    color: var(--icons_stroke_color);
    box-sizing: border-box;
    animation: animloader 1.75s linear infinite;
}

.InputNumberHideArrows {

    appearance: none;

    &::-webkit-inner-spin-button, &::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
    
    -moz-appearance: textfield;
}

@keyframes animloader {
    0% {
        box-shadow: 14px 0 0 -2px, 38px 0 0 -2px, -14px 0 0 -2px, -38px 0 0 -2px;
    }

    25% {
        box-shadow: 14px 0 0 -2px, 38px 0 0 -2px, -14px 0 0 -2px, -38px 0 0 2px;
    }

    50% {
        box-shadow: 14px 0 0 -2px, 38px 0 0 -2px, -14px 0 0 2px, -38px 0 0 -2px;
    }

    75% {
        box-shadow: 14px 0 0 2px, 38px 0 0 -2px, -14px 0 0 -2px, -38px 0 0 -2px;
    }

    100% {
        box-shadow: 14px 0 0 -2px, 38px 0 0 2px, -14px 0 0 -2px, -38px 0 0 -2px;
    }
}