@import "../../../styles/variables.scss";
@import "../../../styles/mixins.scss";

@keyframes fadeInSlideIn {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.fadeInSlideIn {
  animation: fadeInSlideIn 200ms ease-out forwards;
}

.PopupLayout {
  width: calc(100% - $dashboard_side_navbar_width);
  height: calc(100vh - $dashboard_navbar_height);

  position: fixed;
  background-color: var(--dashboard_page_blur);

  overflow: hidden;

  // backdrop-filter: blur(var(--dashboard_blur));

  border-top-left-radius: 16px;

  top: $dashboard_navbar_height;
  left: $dashboard_side_navbar_width;

  transition: all 200ms;

  opacity: 1;

  // SAME PADDING HAS TO BE IN layout.module.scss
  padding: 4rem 4rem;

  @include tablet() {
    padding: 2rem 2rem;
  }

  @include mobile() {
    padding: 1rem 0.75rem;

    left: 0;
    width: 100%;

    height: calc(
      100svh - $dashboard_navbar_mobile_height - $dashboard_navbar_height
    );
  }

  // overflow: hidden;
  // overflow-y: auto;

  display: flex;
  align-items: center;
  justify-content: center;

  z-index: 500;

  .PopupLayoutContentWrapper {
    max-width: max-content;
    height: max-content;
    max-height: calc(
      100svh - $dashboard_navbar_mobile_height - $dashboard_navbar_height
    );
    width: 100%;

    overflow-y: auto;

    @include mobile() {
      max-height: calc(
        100svh - $dashboard_navbar_mobile_height - $dashboard_navbar_height -
          2rem
      );
    }

    .PopupLayoutContent {
      width: 100%;
      max-width: 500px;
      min-width: 500px;

      @include tablet() {
        max-height: 70svh;
        min-width: 300px;
      }

      border-radius: calc($box_border_radius);

      // overflow: hidden;
      // overflow-y: auto;

      background-color: var(--box_default_bg);
      box-shadow: var(--box_box_shadow);

      .ClosePopupContainer {
        width: 100%;

        // background-color: $primary-color;

        display: flex;
        align-items: center;
        justify-content: space-between;

        padding: calc($box_padding * 0.75) calc($box_padding * 0.75);
        padding-bottom: calc($box_padding * 0.5);

        @include mobile() {
          padding: calc($box_padding * 0.5) calc($box_padding * 0.5);
          padding-bottom: calc($box_padding * 0.25);
        }

        .CloseIconContainer {
          display: flex;
          align-items: center;
          justify-content: center;

          cursor: pointer;

          svg {
            width: 24px;
            height: 24px;

            &:hover {
              path {
                stroke: var(--error_color);
              }

              line {
                stroke: var(--error_color);
              }
            }
          }
        }
      }

      .TitleHr {
        margin: 0rem 0rem;
        min-height: 1px;
        max-height: 1px;
        border-radius: 50px;
        background-color: var(--filter_hr_bg);
      }

      .PopupLayoutBody {
        width: 100%;
        padding: calc($box_padding * 0.75);
        padding-top: 0.5rem;
        background-color: var(--box_default_bg);

        border-bottom-left-radius: calc($box_border_radius);
        border-bottom-right-radius: calc($box_border_radius);

        @include mobile() {
          padding: calc($box_padding * 0.5);
        }
      }
    }
  }

  &.canBeOverflowed {
    z-index: 300;
  }
}

.PopupForHero {
  width: 100%;
  height: calc(100%);

  position: fixed;
  // top: $hero_navbar_height;
  top: 0;
  left: 0;

  border-radius: 0;

  z-index: 999;
}
