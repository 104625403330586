@import "../../../../styles/variables.scss";
@import "../../../../styles/mixins.scss";
@import "../../../../styles/text.scss";

// Here we type Component specific styles only 
.TagsContainer {


    .Tag {
        padding: 0.1rem 0.5rem;
        background-color: var(--tag_new_bg);
        border-radius: calc($row_border_radius * 0.5);
        color: var(--white);
        font-weight: 400;
        width: min-content;
        font-size: $default_font_micro_size;

    }

    .SymbolTag {
        background-color: var(--primary_color);
        color: var(--white);
    }

}

.SearchBarStickyTop {
    position: sticky;
    top: calc($dashboard_navbar_height + 10px);

    background-color: var(--sticky_top_bg);
    backdrop-filter: $sticky_top_blur;

    z-index: 400;
}

.BoxTagsContainer {
    display: flex;
    gap: 0.5rem;
    align-items: center;

    .Tag {
        padding: 0.1rem 0.5rem;
        background-color: var(--tag_new_bg);
        border-radius: 25px;
        color: var(--default_white);
        font-weight: 400;
        font-size: 10px;
    }
}

.Hr {
    margin: 0;
    padding: 0;
    min-height: 0.5px;
    max-height: 0.5px;
    border-radius: 50px;
    background-color: var(--box_border_color);
}

.DashboardErrorContainer {
    width: 100%;

    padding: 6rem 0;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    gap: 1rem;

    @include mobile() {
        padding: 2rem 0;
    }
}

.DescriptionBox {
    min-height: 4rem;
    max-height: 4rem;

    @include tablet() {
        min-height: unset;
    }

}


.ClientBoxLabelBox {
    background-color: var(--box_default_bg);
    min-width: 22px;
    max-width: 22px;
    min-height: 22px;
    max-height: 22px;

    border-radius: calc($row_border_radius * 0.5);

    display: flex;
    align-items: center;
    justify-content: center;
}


.ClientBox {
    max-width: calc((100% / 2) - (0.75rem / 2));
    height: 280px;

    @include bigTablet() {
        max-width: unset;
    }

    &.FullWidth {
        max-width: unset;
    }
}