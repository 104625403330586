@import "../../../../styles/variables.scss";
@import "../../../../styles/mixins.scss";

.DashboardNotFound {
    width: 100%;
    // These caluclations are neccessary because of how Our layout (parent) handles height (min-height) 
    // Because of that, we have to use specific height like 100vh and decrese it by paddings added in layout 

    // Important, if we change something in Layout.scss (paddings) we have to recalculate them here
    min-height: calc(100vh - $dashboard_navbar_height - 8rem);


    @include tablet() {
        min-height: calc(100vh - $dashboard_navbar_height - 4rem);
    }

    @include mobile() {
        min-height: calc(100vh - $dashboard_navbar_height - 4rem);
    }

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    gap: 0.25rem;

    font-size: 1.25rem;
    color: var(--section_title_primary);

    .ErrorImage {
        min-width: 300px;
        max-width: 300px;
        height: auto;

        @include mobile() {
            min-width: 250px;
            width: 250px;
        }
    }

    .DashboardNotFoundAdditionalText {
        width: 100%;
        max-width: 500px;
        min-height: 50px;

        margin: 1rem 0;

        background-color: var(--default_white);

        border-radius: 8px;
        padding: 1rem;
    }
}