@import "../../../styles/variables.scss";
@import "../../../styles/mixins.scss";

$sidebar_transition_speed : 150ms;


.Sidebar {
    width: $dashboard_side_navbar_width;
    height: calc(100% - $dashboard_navbar_height);

    user-select: none;

    position: fixed;

    top: $dashboard_navbar_height;
    left: 0px;

    z-index: 999;

    background-color: var(--navbar_sidebar_background);

    @include mobile() {
        width: 0px;
        visibility: hidden;
    }

    transition: width $sidebar_transition_speed;

    // overflow: hidden;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 1rem;

    overflow: scroll;

    // box-shadow: 2px 8px 8px #ccc;

    .SidebarTop {
        width: 100%;

        display: flex;
        flex-direction: column;
        gap: 1rem;

        padding: 0.25rem 0.75rem;
        padding-top: 1rem;
        padding-bottom: 1rem;
    }

    .SidebarBottom {
        width: 100%;

        display: flex;
        flex-direction: column;
        gap: 0.75rem;

        padding: 0.25rem 0.75rem;
        padding-top: 1rem;
        padding-bottom: 1rem;

    }

    .SidebarActionWrapper {

        display: flex;
        gap: 0.5rem;
        align-items: center;

        border-radius: $box_border_radius;

        cursor: pointer;

        &:hover {
            background: var(--sidebar_active_bg);
            box-shadow: var(--sidebar_active_shadow);
        }

        .SidebarActionIconWrapper {
            min-width: calc($dashboard_side_navbar_width - 1.5rem);
            min-height: calc($dashboard_side_navbar_width - 1.5rem);
            max-height: calc($dashboard_side_navbar_width - 1.5rem);

            border-radius: $button_border_radius;

            display: flex;
            align-items: center;
            justify-content: center;

            svg {
                width: $dashboard_side_navbar_icon_width_height;
                height: $dashboard_side_navbar_icon_width_height;
            }

            .IconStroke {
                path, g, circle {
                    stroke: var(--navbar_sidebar_icons_color);
                }
            }

            .IconFill {
                path {
                    fill: var(--navbar_sidebar_icons_color);
                }
            }
        }

        

        &.noHover {
            cursor: unset;
            background-color: transparent;
            box-shadow: unset;
        }

        &::after {
            content: attr(data-label);
            position: fixed;
            left: calc($dashboard_side_navbar_width + 0.5rem);
            white-space: nowrap;
            padding: 0.25rem 0.75rem;
            background-color: var(--navbar_sidebar_background);
            backdrop-filter: blur(2px);
            color: var(--default_black);
            border-radius: calc($box_border_radius / 2);
            opacity: 0;
            pointer-events: none;
            transition: opacity 0.1s ease-in-out;
            font-size: 14px;
            box-shadow: var(--sidebar_active_shadow)
        }

        &:hover::after {
            opacity: 0.9;
        }

        &.showMenu {
            &::after {
            display: none;
            background-color: pink !important;
            }
        }
    }

    .SidebarActionActive {
        background: var(--sidebar_active_bg);
        box-shadow: var(--sidebar_active_shadow);

        &:hover {
            cursor: unset;
            background-color: var(--sidebar_active_bg);
        }

        p {
            color: var(--default_black);
        }

        .SidebarActionIconWrapper {

            .IconStroke {
                path, g, circle {
                    stroke: var(--default_black);
                }
            }

            .IconFill {
                path {
                    fill: var(--navbar_sidebar_background);
                }
            }
        }

        .SideBarDot {
            display: block;

            @include mobile() {
                display: none;
            }
        }
    }

    .ShowOnMobile {
        display: none;

        @include mobile() {
            display: flex;
        }
    }

    .ShowOnTablet {
        display: none;

        @include tablet() {
            display: flex;
        }
    }
}

.SidebarExpaned {
    width: $dashboard_sidebar_expanded_width;

    @include mobile() {
        visibility: visible;
    }
}

.ChangesNotSavedPopup {
    width: 100%;
    height: 100%;

    display: flex;
    flex-direction: column;
    gap: 0.5rem;

    padding: 1.5rem;
}