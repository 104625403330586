@import "../../../styles/variables.scss";
@import "../../../styles/mixins.scss";

$icon_wrapper_size: 50px;

.NavbarMobile {
    width: 100%;
    min-height: $dashboard_navbar_mobile_height;
    max-height: $dashboard_navbar_mobile_height;

    position: fixed;
    bottom: 0;


    display: none;

    @include mobile() {
        display: flex;
    }

    opacity: 1;

    box-shadow: var(--box_box_shadow);

    overflow: hidden;

    z-index: 760;

    .NavbarMobileWrapper {

        width: 100%;

        display: flex;
        align-items: center;
        justify-content: space-evenly;

        background-color: var(--navbar_sidebar_background);

        padding: 0 1rem;

        .IconWrapper {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;

            height: 100%;

            width: 100%;

            // min-width: $icon_wrapper_size;
            // min-height: $icon_wrapper_size;
            // max-width: $icon_wrapper_size;
            // max-height: $icon_wrapper_size;

            cursor: pointer;

            // overflow: hidden;

            &:hover {

                path, g, line, circle {
                    stroke: var(--primary_color);
                }

                p {
                    color: var(--primary_color);
                }
            }

            path, g, line, circle {
                stroke-width: 1.5px;
            }
        }

        .active {
            cursor: unset;

            svg {
                path, g, line, circle {
                    stroke: var(--primary_color);
                }
            }

            p {
                color: var(--primary_color);
            }
        }

    }

    
}