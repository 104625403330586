/* HTML: <div class="loader"></div> */
.ButtonLoadingBars {
    min-width: 15px;
    aspect-ratio: .75;
    --c: no-repeat linear-gradient(var(--white) 0 0);
    background: 
      var(--c) 0%   50%,
      var(--c) 50%  50%,
      var(--c) 100% 50%;
    animation: l7 1s infinite linear alternate;
  }
  @keyframes l7 {
    0%  {background-size: 20% 50% ,20% 50% ,20% 50% }
    20% {background-size: 20% 20% ,20% 50% ,20% 50% }
    40% {background-size: 20% 100%,20% 20% ,20% 50% }
    60% {background-size: 20% 50% ,20% 100%,20% 20% }
    80% {background-size: 20% 50% ,20% 50% ,20% 100%}
    100%{background-size: 20% 50% ,20% 50% ,20% 50% }
  }