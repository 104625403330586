@import "../../../../styles/variables.scss";
@import "../../../../styles/mixins.scss";



.ClientWrapper {

    display: flex;
    flex-direction: row;

    width: 100%;

    .ClientVisits {
        max-height: 500px;
        overflow-y: auto;

        padding: 1.25rem;
        padding-top: 0.25rem;

        gap: 1rem;

        .ClientVisitsWrapper {
            width: 100%;

            display: flex;
            flex-direction: column;
            gap: 0.25rem;

            .ClientVisitColorBox {
                width: 18px;
                height: 10px;

                border-radius: calc($row_border_radius * 0.5)
            }
        }

        .ClientVisit {
            width: 100%;
            padding: 0.5rem 0.75rem;
            gap: 0.25rem;
            border-radius: calc($row_border_radius);
            background-color: var(--on_layout_hover_background);


            .VisitToggleBox {
                width: fit-content;

                input {
                    width: 12px;
                    height: 12px;
                    appearance: auto;
                }
            }
        }

        .ClientVisitAvatar {
            max-width: 14px;
        }
    }

    .AnalyzeContainer {

        width: 100%;

        .AnalyzeBox {
            flex: 1;

            display: flex;
            align-items: center;

            justify-content: space-between;
            gap: 1rem;
            min-width: 100%;

            border-radius: $row_border_radius;

            overflow: hidden;

            // box-shadow: inset 0px 0px 6px $layout_content_background;
            // border: 1px solid var(--box_border_color);

            svg {
                width: 1.5rem;
                height: 1.5rem;
            }
        }

        .ProgressBarWrapper {
            width: 100%;

            display: flex;
            flex-direction: column;
            gap: 0.5rem;

            img {
                max-width: 16px;
            }

            .ProgressBar {
                width: 100%;
                height: 10px;

                background-color: var(--layout_content_background);

                border-radius: 50px;

                overflow: hidden;

                .ProgressBarFill {
                    width: 50px;
                    height: 100%;

                    background-color: var(--primary_color);

                    border-radius: 50px;
                }
            }
        }
    }
}