@import "../../../styles/variables.scss";
@import "../../../styles/mixins.scss";


.SidebarBlurr {
    width: 100%;
    height: 100%;

    background-color: var(--dashboard_page_blur);

    z-index: 799;

    position: fixed;

    transition: background-color 200ms;

    // backdrop-filter: blur($dashboard_blur);

}

.Layout {
    width: 100%;

    // Important! 
    // We use var(--app-height) calculated from Layout.js (dashboard), to control full height of page 
    // It calculates it better for mobile than using 100vh!  (Don't change it unless you are aware of it) 
    // min-height: -webkit-fill-available - helper that will better specify the height on ios devices because on ios height is calculated with Top bar + content + bottom bar (like url bar)
    height: var(--app-height);
    min-height: -webkit-fill-available;


    background-color: var(--layout_content_background);

    padding-top: $dashboard_navbar_height;

    // This will lock scrolling when something is displayed on full screen like popup or sidebar menu blurr 
    // We need to have this in dashboard!


    // Hide scrollbar
    // -ms-overflow-style: none;
    // /* IE and Edge */
    // scrollbar-width: none;

    // /* Firefox */
    // &::-webkit-scrollbar {
    //     display: none;
    // }

    .LayoutContainer {
        width: 100%;

        background-color: var(--navbar_sidebar_background);

        display: flex;

        // For proper navbarMobile render 
        @include mobile(){
            padding-bottom: $dashboard_navbar_mobile_height;
        }
        
        

        .LayoutContent {
            width: calc(100% - $dashboard_side_navbar_width);
            min-height: calc(var(--app-height) - $dashboard_navbar_height);

            background-color: var(--layout_content_background);

            // Here we do the trick to have border left on top in our layout
            border-top-left-radius: 16px;
            margin-left: calc($dashboard_side_navbar_width);

            @include mobile() {
                border-radius: 0px;

                min-height: calc(var(--app-height) - $dashboard_navbar_height - $dashboard_navbar_mobile_height);
                margin-left: 0;
                width: 100%;
            }

            // SAME PADDING HAS TO BE IN PopupLayout.module.scss and SaveChangesPanel
            // Important, if we change something in (paddings) we have to recalculate them for DashboardDataNotFound.scss and DashboardNotFound.scss!
            padding: 4rem 4rem;
            // padding-left: calc(4rem + $dashboard_side_navbar_width);

            @include tablet() {
                padding: 2rem 2rem;
                // padding-left: calc(2rem + $dashboard_side_navbar_width);

            }

            @include mobile() {
                padding: 1rem 0.75rem;
            }

            // Modification for Calendar /calendar 
            // We want him to have more space
            &.CalendarContent {
                padding: 1rem 1rem;
                // padding-left: calc(1rem + $dashboard_side_navbar_width);

                @include tablet() {
                    padding: 1rem 1rem;
                    // padding-left: calc(1rem + $dashboard_side_navbar_width);

                }

                @include mobile() {
                    padding: 0.5rem 0.5rem;
                }
            }

        }
    }
}