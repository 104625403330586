// @import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700;800;900&display=swap');
// font-family: 'Inter', sans-serif;

// @import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700;800&display=swap');

// For Material UI
// @import '@fontsource/roboto/300.css';
// @import '@fontsource/roboto/400.css';
// @import '@fontsource/roboto/500.css';
// @import '@fontsource/roboto/700.css';


// REMEBER
// Root colors, are default, light colors 
// If we add .dark-theme class to App componenet, then they will change to dark :) 

:root {
    --app-height: 100%;
}

html,
body {
    width: 100%;
    min-height: 100vh;

    min-height: var(--app-height);
    min-height: -webkit-fill-available;

    margin: 0;

    // -ms-overflow-style: none;
    // /* IE and Edge */
    // scrollbar-width: none;
    // /* Firefox */

    // &::-webkit-scrollbar {
    //     display: none;
    // }
    // overflow: auto;

    background-color: var(--layout_content_background);
}

html {

    overflow-y: auto;
    color-scheme: light;
    
    &.isDark {
        color-scheme: dark;
    }

    // Set dark theme to all elements inside body
    &.isDark * {
        color-scheme: dark;
    }
}

body {
    overflow: unset;
}




#root {
    width: 100%;

    // Important! 
    // We use var(--app-height) calculated from Layout.js (dashboard), to control full height of page 
    // It calculates it better for mobile than using 100vh!  (Don't change it unless you are aware of it) 
    // min-height: -webkit-fill-available - helper that will better specify the height on ios devices because on ios height is calculated with Top bar + content + bottom bar (like url bar)
    min-height: var(--app-height);
    min-height: -webkit-fill-available;

    margin: 0;
    padding: 0rem;


}

* {
    box-sizing: border-box;

    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    // font-family: 'Poppins', system-ui, sans-serif;

    // For iphone default styles to not overwritte ours !

    -webkit-appearance: none;
    /* remove default appearance on Webkit-based browsers */
    -moz-appearance: none;
    /* remove default appearance on Mozilla-based browsers */
    appearance: none;
    /* remove default appearance on all other browsers */

    stroke-width: 1.25px;

    line-height: 1.37;
}



a {
    text-decoration: none;
    color: unset;
}

p {
    margin: 0;
    padding: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    margin: 0;
}




input,
select,
button,textarea {
    font-family: inherit;

    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;


}

select,
button,
input[type="date"] {
    cursor: pointer;
}

input[type="datetime-local"] {
    cursor: pointer;
}

input[type="date"],
input[type="time"],
select {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
}

button {
    font-family: inherit;
}

input,
select {
    outline: none;
    border: 0;
}
