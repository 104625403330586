@import "../../../styles/mixins.scss";
@import "../../../styles/variables.scss";
@import "../../../styles/text.scss";


.default_input_height {
    height: $input_height;
}

.TextFieldInput textarea, .SingleInput input, .SearchInput, .CurrencyInput input {
    background-color: var(--input_default_bg);
    color: var(--input_default_color);

    outline: none; 
    border: unset;

    border-radius: $input_default_border_radius;

    padding: $input_default_padding;

    border: 1px solid transparent;

    font-size: $default_font_size;

    &[disabled] {
        opacity: 0.5;
        cursor: unset;
    }

    &:focus-visible {
        border: 1px solid var(--primary_color);
    }

    @include tablet() {
        font-size: $default_font_small_size;
    }

    &.fixedHeight {
        height: $input_height;
    }
}

.TextFieldInput {
    width: 100%;
    display: flex;
    justify-content: space-between;

    gap: $gap_between_inputs;

    @include tablet() {
        flex-direction: column;
    }

    textarea {
        width: 100%;
        min-height: 36px;

        background-color: var(--input_default_bg);

        resize: vertical;
    }
}

.SingleInput {
    input {
        width: 100%;

        &.small {
            font-size: $default_font_small_size;
            padding: $input_small_padding;
        }
    }
}

.CurrencyInput {

    border: 1px solid transparent;

    height: 100%;

    input {
        border: 1px solid transparent;
        outline: none; 
        border: unset;
        width: 100%;
    }

    border-radius: $input_default_border_radius;
    overflow: hidden;

    .currency-input-block {
        height: 100%;

        padding: $input_default_padding;

        outline: none;

        font-size: $default_font_size;

        background-color: var(--layout_content_background);

        text-align: center;

        color: var(--default_black);

        display: flex;
        justify-content: center;
        align-items: center;

        @include tablet() {
            font-size: $default_font_small_size;
        }

        user-select: none;
    }

    &.CurrencyInput-focused {
        border: 1px solid var(--primary_color);

        /* example focus style */
        /* other focus styles if needed */

        input {
            outline: none;
            border: none;
        }
    }

    &.fixedHeight {
        height: $input_height;

        .currency-input-block {
            height: $input_height;
            width: $input_height;
        }

    }
}


.SearchInput {
    width: 100%;
    height: $input_height;
    min-width: 200px;
    max-width: 600px;

    @include bigTablet {
        max-width: unset;
    }

    @include tablet() {
        height: $input_height;
    }

    @include mobile() {
        min-width: unset;
        width: 100%;
    }
}