@mixin mobile {
    @media screen and (max-width: 480px) {
        @content;
    }
}

@mixin tablet {
    @media screen and (max-width: 768px) {
        @content;
    }
}

@mixin bigTablet {
    @media screen and (max-width: 1024px) {
        @content;
    }
}

@mixin laptop {
    @media screen and (max-width: 1200px) {
        @content;
    }
}

@mixin desktop {
    @media screen and (max-width: 1400px) {
        @content;
    }
}