@import "../../../styles/variables.scss";
@import "../../../styles/mixins.scss";

// IMPORTANT
// We clear this SaveChangesPanel after 3 seconds (after success message)
// It is impornant because we have to match animations times with it!

// This animation time should match isSuccess or Success timeout time



.PanelHideBarAnimation {
    width: 100%;
    height: 16px;
    max-height: 16px;

    padding: 4px 0.5rem;

    opacity: 0.5;

    transition: all $transition_time;

    .PanelHideWrapper {
        width: 100%;
        height: 100%;

        background-color: var(--layout_content_background);

        border-radius: 50px;

        overflow: hidden;

        .ProgressBar {
            width: 0px;
            height: 100%;

            border-radius: 50px;

            background-color: var(--success_color);

            animation: progressbar 3s linear forwards;
        }
    }

    &.PanelAnimationFinished {
        .ProgressBar {
            animation: unset;
            width: 100%;
        }
    }
}

.SaveChangesPanel {
    width: calc(100% - $dashboard_side_navbar_width);
    min-height: 50px;

    position: fixed; 
    top: $dashboard_navbar_height;
    left: $dashboard_side_navbar_width;

    z-index: 450;

    // background-color: gray;

    padding: 0rem 4rem;

    opacity: 1;

    transition: all $transition_time;

    &.JumpInAnimation {
        animation: jumpIn_Panel 200ms ease-in-out forwards;
    }


    @include mobile {
        left: 0;
    }

    @include tablet() {
        padding: 0rem 2rem;
    }

    @include mobile() {
        padding: 0rem 0.75rem;
        left: 0;
        width: 100%;
    }

    .SaveChangesPanelWrapper {
        display: flex;
        flex-direction: column;
    }

    // background-color: pink;

    .PanelControlls {
        width: 100%;
        min-height: 20px;

        display: flex;
        justify-content: flex-end;

        .PanelControllsWrapper {
            max-width: 300px;

            height: 100%;

            padding: 0.5rem;

            background-color: var(--save_changes_bg);
    
            backdrop-filter: blur(4px);

            box-shadow: var(--box_box_shadow);

            border-bottom-left-radius: 10px;
            border-bottom-right-radius: 10px;



            display: flex;
            gap: 0.5rem;
        }
    }


    .PanelContainer {
        width: 100%;
        min-height: 30px;

        display: flex;
        justify-content: flex-end;

        margin-top: 0.5rem;

        .PanelWrapperActions {

            width: 100%;

            display: flex;
            align-items: flex-end;
            flex-direction: column;

        }

        .PanelToggle {
            width: 100%;

            display: flex;
            align-items: center;
            justify-content: flex-end;

            gap: 0.25rem;

            .PanelToggleButton {
                display: flex;
                align-items: center;
                justify-content: center;

                min-height: 30px;

                padding: 0.5rem 0.75rem;

                gap: 0.25rem;

                background-color: var(--default_white);
    
                backdrop-filter: blur(4px);

                box-shadow: var(--box_box_shadow);

                border-bottom-left-radius: 10px;
                border-bottom-right-radius: 10px;

                &:hover {
                    background-color: var(--primary_color);

                    cursor: pointer;

                    svg {
                        line, g, path { 
                            stroke: var(--white);
                        }
                    }

                    p {
                        color: var(--white);
                    }
                }

            }

            &.PanelToggleHidden {

                .PanelToggleButton {
                    border-radius: 10px;
                }
            }
        }

        .PanelWrapper {
            width: 100%;
            height: 100%;

            // margin-top: 1rem;

            max-width: 400px;
            max-height: 30vh;

            background-color: var(--default_white);
    
            backdrop-filter: blur(4px);

            box-shadow: var(--box_box_shadow);

            border-radius: 4px;
            // border-left: 4px solid var(--success_color);

            display: flex;
            flex-direction: column;
            gap: 0rem;

            overflow: hidden;
            overflow-y: auto;

            transition: all $transition_time;

            z-index: 50;

            opacity: 1;

            // Animation for success box only (default)
            animation: 
                fadeIn_Panel $transition_time  forwards,
                fadeOut_Panel $transition_time calc(3s - $transition_time) ease-in forwards;

            .PanelContent {

                display: flex;
                max-height: 30vh;

                overflow: hidden;
                overflow-y: auto;

                transition: all $transition_time;

                .PanelIconHolder {
                    width: 50px;
                    height: 100%;
    
                    display: flex;
                    align-items: flex-start;
                    justify-content: center;

                    padding: 0.25rem 0.5rem;
                }
    
                .PanelTextHolder {
                    width: 100%;

                    display: flex;
                    flex-direction: column;

                    padding: 0.25rem;
                    padding-bottom: 0.5rem;
                }
    
                .PanelClose {
                    min-width: min-content;
    
                    display: flex;
                    align-items: center;
    
                    cursor: pointer;

                    padding: 0.25rem 1rem;
    
                    &:hover {
                        p {
                            color: var(--default_black);
                        }
                    }
                }
            }

            &.PanelHidden {

                max-width: 0;

                .PanelHideBarAnimation {
                    max-height: 0px;
                    padding: 0;
                }

                .PanelContent {
                    max-height: 0px;
                    padding: 0;
                }
                
            }
            
        }

        // This is additional class when panel is error instead of success
        &.PanelError {

            .PanelWrapper {

                animation: unset;

                border-bottom-right-radius: 0px;

                .PanelHideBarAnimation {
                    .ProgressBar {
                        background-color: var(--error_color);
                    }
                }


            }

        }
    }
}


@keyframes progressbar {
    0% {
        width: 0;
    }
    100% {
        width: 100%;
    }
}


@keyframes fadeIn_Panel {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@keyframes fadeOut_Panel {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

@keyframes jumpIn_Panel {
    0% {
        top: 0;
    }
    100% {
        top: $dashboard_navbar_height;
    }
}