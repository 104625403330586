@import "../../../../styles/variables.scss";
@import "../../../../styles/mixins.scss";
@import "../../../../styles/text.scss";



.tag {
    padding: 0.25rem 0.5rem;
    border-radius: calc($row_border_radius);

    max-width: min-content;

    line-height: 1;

    &.tag_small {
        padding: 0.2rem 0.35rem;
    }
}


.sortArrow {
    width: 1rem;
    height: 1rem;

    g {
        stroke: var(--default_text_color_gray);
    }
}

.PriceListTable {
    height: 100%;
    overflow: hidden;

    thead {

        tr {
            // border-bottom: 1px solid var(--box_border_color);
            // background-color: var(--on_layout_hover_background);

            // border-bottom: 1px solid var(--box_border_color);

            th {
                padding: 0.5rem 0.5rem;
                vertical-align: middle;

                div {
                    display: flex;
                    align-items: center;
                    gap: 0.25rem;
                }

                &.canBeSorted {
                    cursor: pointer;

                    &:hover {
                        color: var(--primary_color);
                    }
                }
            }


        }

    }

    tbody {
        tr {
            border-bottom: 1px solid var(--box_border_color);

            // background-color: var(--layout_content_background);

            &:last-child {
                border-bottom: 0;
            }

            td {
                padding: 1.5rem 0.5rem;
                padding-bottom: 1rem;

                min-width: max-content;
                vertical-align: middle;

                button {
                    background-color: transparent;
                    padding: 0.75rem;
                }

                .LockIcon {

                    path,
                    g,
                    line {
                        stroke: var(--default_black);
                    }

                    &.IconLocked {

                        background-color: var(--primary_color);

                        path,
                                g,
                                line {
                                    stroke: var(--white);
                                }

                        &:hover {
                            svg {

                                path,
                                g,
                                line {
                                    stroke: var(--white);
                                }
                            }
                        }
                    }

                }

            }







            // &:nth-child(even) {
            //     background-color: var(--on_layout_hover_background);
            // }
        }
    }
}

.currentSortingHeader {
    div {
        color: var(--primary_color);
    }


    svg {
        g {
            stroke: var(--primary_color);
        }
    }
}