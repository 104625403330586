

.CheckingHashLoadingPage {
    width: 100%;
    height: 100vh;

    background-color: var(--layout_content_background);

    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 1rem;

    padding: 1rem;

    .CheckingHashWrapper {

        width: 100%;
        max-width: 600px;

        min-height: 400px;

        // border: 1px solid var(--box_border_color);

        .Upper {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            height: 80%;

            gap: 1rem;
        }

        .Lower {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            gap: 1rem;

            height: 20%;
        }

        .CheckingHashActions {
            width: 100%;

            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;

            button {
                font-size: 0.9rem;
            }
        }
    }


}