@import "../variables.scss";
@import "../mixins.scss";

.Wrapper {
  width: 100%;
  min-height: calc(100vh - (2 * $hero_navbar_height));

  display: flex;
  justify-content: center;

  .DataBoxWrapper {
    // border-bottom: 6px solid var(--primary_color);

    z-index: 3;
  }

  animation: slideIn 200ms forwards ease-in-out;
}

@keyframes slideIn {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.05);
  }

  100% {
    transform: scale(1);
  }
}
