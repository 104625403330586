@import "../../../styles/mixins.scss";
@import "../../../styles/variables.scss";

.Layout {
  width: 100%;
  min-height: calc(100vh - $hero_navbar_height);

  background-color: var(--layout_content_background);

  padding: 4rem 1rem;

  @include bigTablet() {
    padding: 2rem 1rem;
  }

  @include mobile() {
    padding: 1rem 1rem;
  }
}

.Wrapper {
  min-height: calc(100vh - ($hero_navbar_height * 2) - 100px);

  margin-top: 100px;

  padding: 0;
}
