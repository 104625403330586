@import "../../../styles/variables.scss";

.Hero {
  width: 100%;
  min-height: calc(100vh);

  padding: 0;

  //   padding-top: $hero_navbar_height;

  background: var(--hero_gradient);
  // background-position-y: 500px;
  //   background-position-x: -100px;
  //   background-repeat: no-repeat;
}

.Layout {
  width: 100%;

  //   background-color: var(--layout_content_background);

  padding: $hero_navbar_height 1rem;

  &.LayoutCenter {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
