// Here we are defining colors for whole app using vars
// default is :root (light-colors) and if we add class .dark-theme then it is a dark theme!

:root {
  // ======== MAIN colors  ========
  --primary_color: #2468fa;
  --on_primary_hover: #4987fe;
  --light_primary_hover: #cbdbff;
  --very_light_primary: #ebf2ff;
  --primary_gradient: linear-gradient(
    60deg,
    rgba(57, 119, 255, 1) 0%,
    rgba(74, 131, 241, 1) 62%,
    rgba(129, 172, 255, 1) 100%
  );

  --premium_color: #eb197e;
  --premium_color_hover: #ff2768;
  --premium_gradient: linear-gradient(
    60deg,
    rgba(235, 25, 126, 1) 0%,
    rgba(255, 39, 104, 1) 50%,
    rgba(246, 33, 85, 1) 100%
  );
  --premium_gradient_hover: linear-gradient(
    60deg,
    rgba(245, 35, 136, 1) 0%,
    rgba(255, 49, 114, 1) 50%,
    rgba(255, 43, 95, 1) 100%
  );
  --blue_gradient_start: rgba(74, 175, 248, 1);

  --icons_background: #f4f4f4;
  --icons_background_hover: #f0f0f0;
  --icons_stroke_color: #222222;
  --icons_inverted_background: #222;
  --icons_inverted_background_hover: #333;

  --hero_gradient: radial-gradient(
    circle,
    rgb(230, 230, 230) 0%,
    rgb(255, 255, 255) 90%,
    rgb(255, 255, 255) 100%
  );

  // ======== Additional colors, for text and icons mostly ========
  // They are not super specified, but can add some colors to our app :)
  --color_green: #58be33;
  --color_blue: #007aff;
  --color_purple: #ac39ff;
  --color_orange: #e66216;

  // --scroll_bar_theme: 'dark';

  --sticky_top_bg: rgba(241, 241, 241, 0.75);

  --radial_gradient_settings: closest-side, white 79%, transparent 80% 100%;

  // ======== Calendar.scss  ========
  --calendar_header_bg: rgba(255, 255, 255, 0.8);
  --calendar_today_box: #f0f0f0;

  --box_border_color: #ddd; // Also in inputs

  --default_white: #fff;
  --default_black: #111;

  // ======== text.scss  ========
  --default_text_color: #222;

  --default_text_color_gray: #999;
  --default_text_color_gray_hover: var(--default_text_color);

  --default_text_white: #fff;
  --default_text_gray_on_layout: #aaa;

  // ======== Dashboard/Layout.js ========
  --layout_content_background: #f1f1f1;
  --on_layout_hover_background: #fdfdfd;

  --dashboard_page_blur: rgba(17, 17, 17, 0.25);
  --dashboard_blur: 3px;

  // ======== Dashboard/Navbar.js + Sidebar.js ========
  --navbar_sidebar_background: #fff;
  --navbar_sidebar_icons_color: rgb(0, 0, 0);
  --sidebar_active_shadow: inset 0px 0px 6px #00000030;
  --sidebar_active_bg: #fff;

  // ======== Dashboard/Wrappers.js ========
  --dashboard_action_bg: var(
    --layout_content_background
  ); // Any button or filter that can be targeted within page
  --box_box_shadow: 2px 4px 12px #00000014;
  --box_box_shadow_light: 1px 2px 4px #00000014;

  --dropdown_shadow: 0px 4px 6px #00000050;

  // ======== Dashboard/Buttons.js ========
  --button_default_color: #fff;

  // error colors
  --button_error_bg: #f52245;
  // --button_error_color: #652628;
  --button_error_bg_hover: #f63356;

  // For button primary
  --button_default_bg: #222;
  --button_default_hover_bg: #333;

  // For button netural
  --button_neutral_bg: #f4f4f4;
  --button_neutral_bg_hover: var(--on_layout_hover_background); // On Layout bg
  --button_neutral_bg_active: var(--primary_color);
  --button_neutral_bg_hover_stronger: #eee; // On White bg
  --button_neutral_color: #222;

  --select_box_border_color: #ddd;

  // ======== Dashboard/FormInputs.js ========
  --input_default_bg: #f4f4f4;
  --input_default_color: var(--button_default_bg);
  --input_box_shadow: inset 0px 0px 2px #bbb;

  // ======== Dashboard/Popups ========
  --popup_bg: #fff;
  --popup_body_bg: #fafafa;
  --popup_shadow: 0px 1px 2px #999;

  // ======== Dashboard/SaveChangesPanel ========
  --save_changes_bg: #f9f9f9dd;

  // ======== Dashboard/FormMessageBoxes ========
  --error_color: rgb(211, 33, 33);
  --error_background: rgb(248, 225, 225);
  --error_gradient: linear-gradient(
    60deg,
    rgb(245, 35, 63) 0%,
    rgb(240, 58, 74) 50%,
    rgb(250, 31, 42) 100%
  );
  --error_box_shadow: 0px 2px 4px rgba(var(--error_color), 0.25);

  --success_color: rgb(43, 167, 43);
  --success_background: rgb(230, 248, 225);
  --success_dark_background: rgb(46, 114, 46);

  --neutral_gray_color: #4a4a4a; // In NeutralContainer

  // ======== PriceList.scss  ========
  --star_stroke: var(--error_color);
  --star_fill: var(--error_background);

  // ======== Dashboard/ MultiPurpose styles ========
  --filter_hr_bg: #ddd; // Background color of HR element filter

  --tag_new_bg: #444; // Background of 'New' Tag

  --box_default_bg: #f9f9f9; // Every box or card background
  --box_on_default_bg: #fff;
  --box_default_hover_shadow: 0px 3px 4px #ccc;

  // ======== Dashboard/ Client.js ========
  --selected_image_background: #fff;
  --animal_image_shadow: inset 0px 0px 12px
    rgba(var(--neutral_gray_color), 0.15);

  // ======== Unchangable colors (we dont make dark version of them ========
  --white: #fff;
  --black: #000;

  // TO BE DELETED LATER
  // ======== Google_MUI_Styles ========
  // Login Button color (outlined) (br -> border-color)
  --mu_login_br: var(--primary_color);
  --mu_login_color: var(--primary_color);

  // Sign in Button color (contained)
  --mu_signin_bg: var(--primary_color);
  --mu_signin_color: #fff;

  // Sing in with Google Facebook (Gray outline)
  --mu_media_br: #4a4a4a;
  --mu_media_color: var(--mu_media_br);

  // ======== Google_MUI_Snackbar ========
  --snackbar_bg: #222;
  --snackbar_color: #fff;

  /* ======= Page Home Old Styles ======= */

  // ## Fonts
  --section_title_primary: #111;

  // ## Home - Hero
  --hero_background: #fff;

  // On home page, all the gray text is this
  --font_primary_gray: #6b6b6b;

  // ## Home - Features
  --features_background: #f7f7ff;

  // ## Footer
  --footer_hr: #ccccd1;
  --footer_bg: #433ec9;
  --footer_color: #eee;
}

.dark-theme {
  // ======== MAIN colors  ========
  --primary_color: #2468fa;
  --on_primary_hover: #4987fe;
  --light_primary_hover: #cbdbff;
  --very_light_primary: #ebf2ff;

  --premium_color: #eb197e;
  --premium_color_hover: #ff2768;

  --blue_gradient_start: rgba(74, 175, 248, 1);

  --hero_gradient: radial-gradient(
    circle,
    rgba(35, 44, 65, 1) 0%,
    rgba(17, 17, 17, 1) 77%,
    rgba(17, 17, 17, 1) 100%
  );

  --icons_background: #444;
  --icons_background_hover: #555;
  --icons_stroke_color: #ddd;
  --icons_inverted_background: #444;
  --icons_inverted_background_hover: #555;

  --scroll_bar_theme: "dark";

  --sticky_top_bg: rgba(34, 34, 34, 0.75);

  --radial_gradient_settings: closest-side, var(--box_default_bg) 79%,
    transparent 80% 100%;

  // ======== Calendar.scss  ========
  --calendar_header_bg: rgba(34, 34, 34, 0.8);
  --calendar_today_box: #2a2a2a;

  --box_border_color: #444; // Also in inputs

  --default_white: #222;
  --default_black: #eee;

  // ======== text.scss  ========
  --default_text_color: #eee;

  --default_text_color_gray: #ddd;
  --default_text_color_gray_hover: var(--default_text_color);

  --default_text_white: #fff;
  --default_text_gray_on_layout: #aaa;

  // ======== Dashboard/Layout.js ========
  --layout_content_background: #111;
  --on_layout_hover_background: #2a2a2a;

  --dashboard_page_blur: rgba(11, 11, 11, 0.45);
  --dashboard_blur: 3px;

  // ======== Dashboard/Navbar.js + Sidebar.js ========
  --navbar_sidebar_background: #222;
  --navbar_sidebar_icons_color: rgb(255, 255, 255);
  --sidebar_active_shadow: inset 0px 0px 6px #ffffff20;
  --sidebar_active_bg: #222;

  // ======== Dashboard/Wrappers.js ========
  --dashboard_action_bg: #777; // Any button or filter that can be targeted within page
  --box_box_shadow: 2px 4px 12px #00000050;
  --box_box_shadow_light: 1px 2px 4px #00000050;

  --dropdown_shadow: 0px 4px 6px #00000050;

  // ======== Dashboard/Buttons.js ========
  --button_default_color: #fff;

  // error colors
  --button_error_bg: #f52245;
  // --button_error_color: #652628;
  --button_error_bg_hover: #f63356;

  // For button primary
  --button_default_bg: #ddd;
  --button_default_hover_bg: #eee;

  // For button netural
  --button_neutral_bg: #333;
  --button_neutral_bg_hover: #444; // On Layout bg
  --button_neutral_bg_active: var(--primary_color);
  --button_neutral_bg_hover_stronger: #2a2a2a; // On Black Bg
  --button_neutral_color: #eee;

  --select_box_border_color: #ddd;

  // ======== Dashboard/FormInputs.js ========
  --input_default_bg: #292929;
  --input_default_color: var(--button_default_bg);
  --input_box_shadow: inset 0px 0px 2px #444;

  // ======== Dashboard/Popups ========
  --popup_bg: #444;
  --popup_body_bg: #3a3a3a;
  --popup_shadow: 0px 1px 2px #999;

  // ======== Dashboard/SaveChangesPanel ========
  --save_changes_bg: #282828dd;

  // ======== Dashboard/FormMessageBoxes ========
  --error_color: rgb(238, 62, 62);
  --error_background: rgb(63, 39, 39);
  --error_box_shadow: 0px 2px 4px rgba(var(--error_color), 0.25);

  --success_color: rgb(22, 163, 76);
  --success_background: rgb(39, 63, 45);

  --neutral_gray_color: #4a4a4a; // In NeutralContainer

  // ======== PriceList.scss  ========
  --star_stroke: var(--error_color);
  --star_fill: var(--error_background);

  // ======== Dashboard/ MultiPurpose styles ========
  --filter_hr_bg: var(
    --box_border_color
  ); // Background color of HR element filter

  --tag_new_bg: #444; // Background of 'New' Tag

  --box_default_bg: #222; // Every box or card background
  --box_on_default_bg: #292929;
  --box_default_hover_shadow: 0px 3px 4px #333;

  // ======== Dashboard/ Client.js ========
  --selected_image_background: #fff;
  --animal_image_shadow: inset 0px 0px 12px
    rgba(var(--neutral_gray_color), 0.15);

  // TO BE DELETED LATER
  // ======== Google_MUI_Styles ========
  // Login Button color (outlined) (br -> border-color)
  --mu_login_br: var(--primary_color);
  --mu_login_color: var(--primary_color);

  // Sign in Button color (contained)
  --mu_signin_bg: var(--primary_color);
  --mu_signin_color: #fff;

  // Sing in with Google Facebook (Gray outline)
  --mu_media_br: #4a4a4a;
  --mu_media_color: var(--mu_media_br);

  // ======== Google_MUI_Snackbar ========
  --snackbar_bg: #222;
  --snackbar_color: #fff;

  /* ======= Page Home Old Styles ======= */

  // ## Fonts
  --section_title_primary: #111;

  // ## Home - Hero
  --hero_background: #fff;

  // On home page, all the gray text is this
  --font_primary_gray: #6b6b6b;

  // ## Home - Features
  --features_background: #f7f7ff;

  // ## Footer
  --footer_hr: #ccccd1;
  --footer_bg: #433ec9;
  --footer_color: #eee;
}
