@import "./variables.scss";
@import "./mixins.scss";

// These are our SVG styles, they mostly differ between FIll and stroke
.SVG_FILL {
    
    path {
        fill: var(--icons_stroke_color);
        stroke: unset;
    }
}

.SVG_FILL_HOVER {
    fill: unset;
    stroke: unset;

    &:hover {
        svg {
            path {
                fill: var(--icons_stroke_color);
                stroke: unset;
            }
        }
    }
}

.SVG_STROKE_ERROR {
    path {
        stroke: var(--error_color);
        fill: unset;
    }

    line, circle  {
        stroke: var(--error_color);
    }
}

.SVG_STROKE {
    path {
        stroke: var(--icons_stroke_color);
        fill: unset;
    }

    line, circle , g {
        stroke: var(--icons_stroke_color);
    }
}

.SVG_STROKE_GREEN {
    path {
        stroke: var(--color_green);
        fill: unset;
    }

    line, circle , g {
        stroke: var(--color_green);
    }
}

.SVG_STROKE_ORANGE {
    path {
        stroke: var(--color_orange);
        fill: unset;
    }

    line, circle , g {
        stroke: var(--color_orange);
    }
}

.SVG_STROKE_PURPLE {
    path {
        stroke: var(--color_purple);
        fill: unset;
    }

    line, circle , g {
        stroke: var(--color_purple);
    }
}

.SVG_STROKE_BLUE {
    path {
        stroke: var(--color_blue);
        fill: unset;
    }

    line, circle , g {
        stroke: var(--color_blue);
    }
}

.SVG_STROKE_WHITE {
    path {
        stroke: var(--white);
        fill: unset;
    }

    line, circle, g {
        stroke: var(--white);
    }
}


.SVG_STROKE_PRIMARY {
    path {
        stroke: var(--primary_color);
        fill: unset;
    }

    line, circle {
        stroke: var(--primary_color);
    }
}

.SVG_STROKE_GRAY {
    path {
        stroke: var(--default_text_color_gray);
        fill: unset;
    }

    line, circle, polyline {
        stroke: var(--default_text_color_gray);
    }
}

.SVG_STROKE_SUCCESS {
    path {
        stroke: var(--success_color);
        fill: unset;
    }

    line, circle, polyline {
        stroke: var(--success_color);
    }
}

.SVG_STROKE_HOVER {
    fill: unset;
    stroke: unset;

    &:hover {
        path {
            fill: unset;
            stroke: var(--icons_stroke_color);
        }
        line, circle, polyline {
            stroke: var(--icons_stroke_color);
        }
    }
}

.SVG_DEFAULT {
    path {
        stroke: var(--button_default_color);
        fill: unset;
    }

    circle {
        stroke: var(--button_default_color);
    }
}




// SVG SIZES 


.svg_big {
    width: $svg_big_width;
    height: $svg_big_height;
}

.svg_default {
    width: $svg_width;
    height: $svg_height;
}

.svg_medium {
    width: $svg_medium_width;
    height: $svg_medium_height;

    @include tablet {
        width: $svg_small_width;
        height: $svg_small_height;
    }
}

.svg_small {
    width: $svg_small_width;
    height: $svg_small_height;
}
