@import "../../styles/mixins.scss";
@import "../../styles/variables.scss";

.UserImage {
  max-width: 32px;
  max-height: 32px;
  min-width: 32px;
  min-height: 32px;

  user-select: none;
}

// WHen we want to hold some width until tablet
// Used in ClientTable
.MinWidthUntillTablet {
  min-width: 150px;
  max-width: 150px;

  @include tablet() {
    min-width: unset;
  }
}

.ComponentLoading {
  width: calc(100vw - $dashboard_side_navbar_width);
  height: calc(100vh - $dashboard_navbar_height);
  position: fixed;
  top: $dashboard_navbar_height;
  left: $dashboard_side_navbar_width;

  z-index: 100;

  background-color: var(--dashboard_page_blur);
  backdrop-filter: blur(var(--dashboard_blur));

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 1rem;

  @include mobile() {
    width: 100vw;
    hight: 100vh;
    top: $dashboard_navbar_height;
    left: 0;
  }
}
