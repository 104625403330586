// Scale Up animation 
// Used when we QuickSelect latest added client in add visit popup
.scale-animation {
    transform: scale(1.04);
    transition: transform 150ms linear;
}

.no-animation {
    transform: scale(1);
    transition: transform 150ms linear;
}