@import "./mixins.scss";

/*
    Description:

    Here we add all the neccessary variables that we might need and we want to controll from here
*/


// ====== All Variables for Dashboard and Dashboard/Elements ======



// Change this so you can change all Navbar and its padding-bottom to have nice Navbar 
$hero_navbar_height: 64px;
$hero_footer_height: 56px;

// Dashboard
$dashboard_navbar_height: 80px;

$dashboard_navbar_mobile_height: 64px;

$dashboard_side_navbar_width: 80px;
$dashboard_side_navbar_icon_width_height: 30px;

// Dont change this unless you want to recalculate whole sidebar for that
$dashboard_sidebar_icon_width_height: 24px;
$dashboard_sidebar_expanded_width: 224px;


// ====== Multi Purpose variables ======


// ====== Wrappers ======
$row_border_radius: 4px;
$row_padding: 0.25rem 1rem;

$box_border_radius: 18px;
$box_smaller_border_radius: 10px;
$box_padding: 2rem;

$table_icon_radius: 50px;
$table_icon_width: 32px;
$table_icon_height: 32px;

$parent_padding: 1rem;
$tablet_padding: 1rem;

$parent_gap: 0.5rem;
$tablet_gap: 0.5rem;

$select_image_gap: 0.5rem;
$image_padding: 0.35rem;

$sticky_top_blur: 2px;



$transition_time: 300ms; // This single variable controlls our all animations! 


// ====== Form Messages ======





// ====== Form Labels ======

$gap_from_icon: 0.5rem;

$label_icon_size: 24px;
$label_icon_size_tablet: 16px;


// ====== SVG's ======

$svg_big_width: 36px;
$svg_big_height: 36px;

$svg_width: 26px;
$svg_height: 26px;

$svg_medium_width: 20px;
$svg_medium_height: 20px;

$svg_small_width: 16px;
$svg_small_height: 16px;

// ====== Form Inputs ======
$gap_between_inputs: 1rem;

$input_default_border_radius: 6px;

$input_default_padding: 0.75rem 0.75rem;
$input_small_padding: 0.5rem 0.5rem;
$input_height: 40px;

$input_font_weight: 400;

// ====== Form Buttons ======
$button_border_radius: 6px;
$button_fav_radius: 50px;

$button_bold: 400;
$button_bold_500: 500;

$button_default_padding: 0.75rem 1.25rem;
$button_small_padding: 0.65rem 1.15rem;