@import "../../../styles/mixins.scss";
@import "../../../styles/variables.scss";
@import "../../../styles/text.scss";

.ErrorContainer,
.SuccessContainer {
  width: 100%;

  border-radius: $box_smaller_border_radius;

  min-height: min-content;

  background-color: var(--layout_content_background);

  backdrop-filter: blur(4px);

  // box-shadow: var(--box_box_shadow);

  margin-top: 0.25rem;
  margin-bottom: 0.25rem;

  display: flex;

  gap: 0.75rem;

  padding: 1rem 0.75rem;

  z-index: 10;

  max-height: 200px;

  overflow: hidden;
  overflow-y: auto;

  .c-icon-holder {
    // background-color: var(--success_background);

    display: flex;
    align-items: flex-start;
    justify-content: center;

    border-radius: 10px;
  }

  .c-message-container {
    padding: 0;

    .c-message-holder {
      // padding: 0.25rem;
      padding: 0;

      gap: 0.25rem;
    }
  }
}

// Error or Success Box
// Those are error and succes boxes in login, signin, add new client and new visit
.ErrorContainer {
  animation: 1s shake;

  background-color: var(--error_background);
}

.SuccessContainer {
  background-color: var(--success_background);
}

// Animation shake, for error box, makes it easier to understand that we have an error
@keyframes shake {
  10%,
  90% {
    transform: translateX(-5px);
    /* Adjust the amount and direction of the shake */
  }

  20%,
  80% {
    transform: translateX(5px);
    /* Adjust the amount and direction of the shake */
  }

  30%,
  50%,
  70% {
    transform: translateX(-5px);
    /* Adjust the amount and direction of the shake */
  }

  40%,
  60% {
    transform: translateX(5px);
    /* Adjust the amount and direction of the shake */
  }
}

// Loading animation circles
.loading_animation_circles {
  width: 1rem;
  height: 1rem;
  border-radius: 50px;
  display: block;
  border-top: 3px solid var(--primary_color);
  border-right: 2px solid transparent;
  animation: rotation 1s linear infinite;

  &.onSubmit {
    border-top: 3px solid var(--default_white);
  }
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
