@import "../../../../styles/variables.scss";

// Shared styles between subpages in user-settings 

.TableWrapper {
    width: 100%;
    overflow-x: auto;

    border-radius: $box_border_radius;
    border: 1px solid var(--layout_content_background)
}

.CurrentPlan {
    background-color: var(--light_primary_hover);
    position: absolute;
    top: -14px;
    left: -8px;

    width: max-content;
    height: min-content;

    padding: 4px 0.5rem;
    border-radius: $box_border_radius;
}

.Table {
    width: 100%;

    
    border-collapse: collapse;
    overflow: hidden;

    

    thead {
        
        background-color: var(--layout_content_background);

        tr {
            th {
                padding: 1rem 0.5rem;
                text-align: left;

                text-wrap: nowrap;

                // font-size: 1.1rem;
            }
        }

    }

    tbody {

        tr {

            border-bottom: 1px solid var(--box_border_color);

            td {
                padding: 2.5rem 0.5rem;
                min-width: max-content;
                vertical-align: middle;
                text-wrap: nowrap;
            }

            &:last-child {
                border-bottom: 1px solid transparent;
            }

            // &:nth-child(even) {
            //     background-color: var(--box_border_color);
            // }
        }
    }
}
