@import "../variables.scss";
@import "../mixins.scss";

.Dashboards {
  width: 100%;
  min-height: calc(100vh - (2 * $hero_navbar_height));

  display: flex;
  align-items: center;
  justify-content: center;
  // background-color: var(--layout_content_background);

  .DashboardsWrapper {
    // width: 100%;

    // padding: 6rem 0.75rem;

    display: flex;
    flex-direction: column;
    gap: 4rem;

    // @include tablet() {
    //     padding: 3rem 0.75rem;
    // }

    .DashboardPendingInvitations {
      width: 100%;
      max-height: 300px;

      overflow: scroll;

      display: flex;
      flex-direction: column;
      gap: 1rem;

      .DashboardPendingInvitation {
        width: 100%;
        border-radius: 8px;
        background-color: var(--on_layout_hover_background);

        padding: 1rem;

        display: flex;
        gap: 1rem;
        justify-content: space-between;
        align-items: center;

        p {
          width: 100%;
          text-align: left;
        }

        @include tablet() {
          flex-direction: column;

          div {
            align-self: flex-end;
          }
        }

        div {
          width: max-content;
          display: flex;
          flex-wrap: nowrap;
        }
      }
    }

    .DashboardsHolder {
      width: 100%;
    }
  }
}
