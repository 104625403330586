@import "../../../styles/mixins.scss";
@import "../../../styles/variables.scss";
@import "../../../styles/text.scss";

.LabelWithLeftIconAndScrollIcon,
.LabelLeftIconTwoBoldsTexts,
.LabelLeftIconTwoBoldsTexts,
.LabelLeftSingleIcon {
    margin-top: 1rem;

    min-height: max-content;
    overflow: hidden;

    color: var(--default_text_color);

    display: flex;
    align-items: center;
    gap: $gap_from_icon;

    b {
        font-weight: $bold_font_weight;
    }

    svg {
        width: $label_icon_size;
        height: $label_icon_size;

        path {
            stroke: var(--default_text_color);
        }
    }

    @include tablet() {

        svg {
            width: $label_icon_size_tablet;
            height: $label_icon_size_tablet;

        }

    }
}

.LabelWithLeftIconAndScrollIcon {
    width: 100%;

    justify-content: space-between;
}

.LabelLeftIconTwoBoldsTexts {
    width: 100%;

}

.LabelLeftSingleIcon {
    width: 100%;
}