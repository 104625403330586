@import "../../../styles/mixins.scss";
@import "../../../styles/variables.scss";

.Navbar {
  width: 100%;
  min-height: $hero_navbar_height;

  position: fixed;

  z-index: 50;

  background-color: var(--navbar_sidebar_background);

  .NavbarMobileExpanded {
    width: 100%;
    // min-height: max-content;
    max-height: max-content;
    padding: 1rem;

    flex-direction: column;
    gap: 0.25rem;

    @include bigTablet() {
      display: flex;
    }

    display: none;

    overflow: hidden;

    user-select: none;

    box-shadow: 0px 4px 6px #00000025;

    animation: slideIn 200ms forwards linear;
  }

  @keyframes slideIn {
    0% {
      height: 0px;
    }
    99% {
      height: 500px;
    }
    100% {
      height: 500px;
      overflow-y: auto;
    }
  }

  .NavbarWrapper {
    width: 100%;
    min-height: $hero_navbar_height;
    height: 100%;

    display: flex;
    gap: 1rem;

    @include bigTablet() {
      padding: 0 1rem;
    }

    .NavbarLeft,
    .NavbarRight {
      width: 100%;
      min-height: $hero_navbar_height;

      // background-color: gray;

      display: flex;
      gap: 1rem;
      align-items: center;
    }

    .NavbarLeft {
      flex: 2;

      .NavbarLogo {
        max-height: calc($hero_navbar_height - 10px);
        min-height: calc($hero_navbar_height - 10px);
      }
    }

    .NavbarRight {
      flex: 1;
      justify-content: flex-end;

      .NavbarBurger {
        width: calc($hero_navbar_height - 1rem);
        height: calc($hero_navbar_height - 1rem);

        display: flex;
        align-items: center;
        justify-content: center;

        border-radius: 8px;

        cursor: pointer;

        &:hover {
          background-color: #ffffff10;
        }

        display: none;

        @include bigTablet() {
          display: flex;
        }

        svg {
          min-width: 32px;
          min-height: 32px;
        }
      }
    }
  }
}

.NavbarMobileLink,
.NavbarMobileLinkNoHover {
  font-size: 1rem;

  color: var(--default_black);

  border-radius: 8px;

  padding: 0.75rem 0.75rem;

  display: flex;
  gap: 0.5rem;
  align-items: center;
}

.NavbarLink {
  font-size: 1rem;
  color: var(--default_black);

  border-radius: 8px;

  padding: 0.4rem 0.75rem;

  &:hover {
    background-color: var(--layout_content_background);
    color: var(--default_black);
  }

  @include bigTablet() {
    display: none;
  }
}

.NavbarMobileLink,
.NavbarMobileLink {
  &:hover {
    background-color: var(--layout_content_background);
    color: var(--default_black);
  }
}
