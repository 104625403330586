@import "../variables.scss";
@import "../mixins.scss";

.PageNotFound {
  width: 100%;
  min-height: calc(100vh - (2 * $hero_navbar_height));
  // background-color: var(--layout_content_background);

  display: flex;
  align-items: center;
  justify-content: center;

  .PageNotFoundWrapper {
    // width: 100%;
    // height: calc(100vh - $hero_navbar_height - 8rem);

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    gap: 0.25rem;

    font-size: 1.25rem;
    color: var(--section_title_primary);

    .ErrorImage {
      min-width: 300px;
      max-width: 300px;
      height: auto;

      @include mobile() {
        min-width: 250px;
        width: 250px;
      }
    }
  }
}
