@import "../../../../styles/variables.scss";
@import "../../../../styles/mixins.scss";


.DashboardNoPermisions {
    width: 100%;
    background-color: var(--layout_content_background);

    .DashboardNoPermisionsWrapper {
        // width: 100%;
        min-height: calc(100vh - $hero_navbar_height);

        padding: 6rem 0.75rem;

        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        gap: 0.25rem;

        font-size: 1.25rem;
        color: var(--section_title_primary);

        @include tablet() {
            padding: 3rem 0.75rem;
        }

        .ErrorImage {
            min-width: 300px;
            max-width: 300px;
            height: auto;

            @include mobile() {
                min-width: 250px;
                width: 250px;
            }
        }
    }


}