@import "../../../styles/mixins.scss";
@import "../../../styles/variables.scss";
@import "../../../styles/text.scss";

// IMPORTANT
// IF WE HAVE PADDING SET TO 1 rem it means that we cannot make it dynamic
// Some elements might be shrinked too much etc buttons

.Button_WhiteBg {
  background-color: var(--default_white);
}

// Some Wrappers on different device
.FlexWrapOnMobile {
  @include mobile() {
    flex-wrap: wrap;
  }
}

.FlexWrapOnTablet {
  @include tablet() {
    flex-wrap: wrap;
  }
}

.FlexWrapOnBigTablet {
  @include bigTablet() {
    flex-wrap: wrap;
  }
}

// Addons
.BoxBg {
  background-color: var(--box_default_bg);
  box-shadow: var(--box_box_shadow);
  border-radius: $row_border_radius;
}

.BoxDanger {
  // box-shadow: $error-box-shadow;
  // border: 1px solid $error-color;
}

.Dashboard_Color_Cirlce {
  width: 14px;
  height: 14px;
  border-radius: 50px;

  min-width: 14px;
  max-width: 14px;

  min-height: 14px;
  max-height: 14px;
}

// This is style that we add to every box on the page
.BoxStyle {
  background-color: var(--box_default_bg);
  border-radius: $row_border_radius;
  border: 1px solid var(--box_border_color);

  &.inputStyles {
    border-radius: $input_default_border_radius;
  }

  &.RowborderRadius {
    border-radius: $row_border_radius;
  }
}

// Actuall Wrappers
.NormalBox {
  flex: 1;
  min-width: 300px;

  background-color: var(--box_default_bg);

  border-radius: calc($row_border_radius * 0.5);
  // border: 1px solid var(--box_border_color);

  // padding: $parent_padding;

  display: flex;
  flex-direction: column;
  gap: $parent_gap;

  @include tablet() {
    gap: $tablet_gap;
    padding: $tablet_padding;
    min-height: unset;
  }

  &.NormalBox_ControlPanel {
    width: 100%;
    min-width: 225px;
    max-width: 300px;
    flex: unset;

    background-color: var(--on_layout_hover_background);

    @include bigTablet() {
      max-width: unset;
    }
  }
}

.DataBox {
  width: 100%;

  display: flex;
  flex-direction: column;
  gap: 2rem;

  overflow: auto;
  overflow: hidden;

  padding: 2rem;
  border-radius: $box_border_radius;

  @include tablet() {
    padding: 2rem;
  }

  @include mobile() {
    padding: 1.5rem;
  }

  // This style adds background and shadow when
  // element is on layout color (gray)
  &.onLayout {
    background-color: var(--box_default_bg);
    box-shadow: var(--box_box_shadow);
  }

  &.border {
    border: 1px solid var(--box_border_color);
  }

  &.premiumBox {
    flex-direction: row;
    justify-content: space-between;

    // border: 1px solid var(--premium_color);
    background: var(--premium_gradient);
  }

  &.primaryGradient {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    background: rgb(74, 175, 248);
    background: var(--primary_gradient);
  }

  &.centerAll {
    align-items: center;
    justify-content: center;
  }

  &.smallerGap {
    gap: 0.5rem;
  }

  &.smallerPadding {
    padding: 1.5rem;
  }

  &.noShadow {
    box-shadow: unset;
  }

  // When we have databox as input at the top for example in clients (search clients)
  &.asMainInput {
    padding: 0.5rem 0.5rem;
    gap: 0.5rem;

    flex-direction: row;

    margin-bottom: 1rem;

    border-radius: 4px;
  }
}

.VisitBox {
  display: flex;
  flex-direction: column;
  gap: 8px;

  max-height: 120px;
  overflow: scroll;

  box-shadow: var(--box_box_shadow_light);
  background-color: var(--on_layout_hover_background);

  padding: 12px;

  border-width: 6px;
  border-left-style: solid;
  border-radius: 6px;
}

.LinkWithSVG {
  display: flex;
  align-items: center;
  gap: 4px;

  cursor: pointer;

  width: max-content;

  svg {
    width: 16px;
    height: 16px;

    stroke-width: 1.5px;
  }

  &:hover {
    color: var(--default_text_color_gray_hover);

    svg {
      line {
        stroke: var(--default_text_color_gray_hover);
      }

      polyline {
        stroke: var(--default_text_color_gray_hover);
      }
    }
  }
}

.IconBox {
  display: flex;
  flex-direction: column;
  justify-content: center;

  padding: 4px;
  border-radius: 8px;

  aspect-ratio: 1/1;

  transition: all 0.3s;

  &.premiumBox {
    background-color: var(--premium_color);

    svg {
      line {
        stroke: var(--white);
      }

      path {
        stroke: var(--white);
      }

      polyline {
        stroke: var(--white);
      }
    }
  }

  &.premiumArrow {
    border-radius: 50px;
    cursor: pointer;

    background-color: var(--white);

    line,
    polyline,
    path {
      stroke: var(--premium_color);
    }

    &:hover {
      transform: scale(1.1);
    }
  }

  &.primaryGradientArrow {
    border-radius: 50px;
    cursor: pointer;

    background-color: var(--white);

    line,
    path,
    polyline {
      stroke: var(--primary_color);
    }

    &:hover {
      transform: scale(1.1);
    }
  }
}

.ClickableIconWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  padding: 4px;

  border-radius: $button_border_radius;

  cursor: pointer;

  &.onWhiteHover_STROKE_GRAY {
    &:hover {
      background-color: var(--layout_content_background);

      svg {
        line {
          stroke: var(--icons_stroke_color);
        }

        path {
          stroke: var(--icons_stroke_color);
        }

        circle {
          stroke: var(--icons_stroke_color);
        }

        polyline {
          stroke: var(--icons_stroke_color);
        }
      }
    }
  }

  &.onWhiteHover_STROKE_DANGER {
    &:hover {
      background-color: var(--error_background);

      svg {
        line {
          stroke: var(--error_color);
        }

        path {
          stroke: var(--error_color);
        }

        circle {
          stroke: var(--error_color);
        }

        polyline {
          stroke: var(--error_color);
        }
      }
    }
  }
}

.DataBoxWrapper {
  flex: 1;
  min-width: 350px;
  min-height: 350px;
  max-height: 400px;

  overflow-y: scroll;

  display: flex;
  flex-direction: column;
  gap: $parent_gap;

  // border: 1px solid var(--box_border_color);

  background-color: var(--box_default_bg);

  padding: 2rem;
  border-radius: $box_border_radius;

  @include tablet() {
    padding: 2rem;
  }

  @include mobile() {
    padding: 1.5rem;
  }

  transition: all 200ms;

  &:hover {
    transform: scale(1.01);
  }

  &.DataBox_style {
    background-color: var(--box_default_bg);
    box-shadow: var(--box_box_shadow);
  }

  &.noHover {
    &:hover {
      transform: none;
    }
  }

  &.SingleDashboard {
    max-width: calc((100% / 2) - 1rem / 1.5);

    @include tablet() {
      max-width: unset;
    }
  }

  @include tablet() {
    min-width: 250px;
    min-height: unset;

    gap: $tablet_gap;
  }
}

.BoxTagsContainer {
  display: flex;
  gap: 0.5rem;
  align-items: center;

  .Tag {
    padding: 0.1rem 0.5rem;
    background-color: var(--tag_new_bg);
    border-radius: calc($row_border_radius * 0.5);
    color: var(--default_text_white);
    font-weight: $default_font_weight;
    font-size: $default_font_micro_size;

    display: flex;
    align-items: center;
    gap: 0.25rem;

    user-select: none;

    svg {
      width: 14px;
      height: 14px;

      path {
        stroke: var(--default_text_white);
      }
    }

    &.tag_primary {
      background-color: var(--primary_color);
    }

    &.tag_success {
      background-color: var(--success_background);
      color: var(--success_color);

      svg {
        path {
          stroke: var(--success_color);
        }
      }
    }
  }
}

.WrapperGrid4 {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 2rem;

  &.onTablet {
    @include tablet() {
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: repeat(2, auto);

      gap: 1rem;
    }
  }

  &.smallerGap {
    gap: 1rem;
  }
}

.WrapperGrid2 {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 2rem;

  &.FirstElementBigger {
    grid-template-columns: 2fr 1fr;
  }

  // Used in /user-settings
  &.LeftMenuTemplate {
    grid-template-columns: 1fr 4fr;
  }

  &.onTablet {
    @include tablet() {
      grid-template-columns: repeat(1, 1fr);
      grid-template-rows: repeat(1, auto);

      gap: 1rem;
    }
  }

  &.onBigTablet {
    @include bigTablet() {
      grid-template-columns: repeat(1, 1fr);
      grid-template-rows: repeat(1, auto);
    }
  }

  &.smallerGap {
    gap: 1rem;
  }
}

.PerrmissionBox {
  padding: 0.5rem 1rem;
  background: var(--layout_content_background);

  min-width: min-content;
  text-align: center;

  border-radius: 10px;

  p {
    color: var(--default_black);
  }

  &.isOwnerBox {
    background: var(--premium_gradient);

    p {
      color: var(--white);
    }
  }
}

.WrapperFlexRow {
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: row;
  align-items: center;
  gap: $parent_gap;

  padding: $parent_padding;

  @include tablet() {
    gap: $tablet_gap;
    padding: $tablet_padding;
  }

  &.WrapperTitle {
    padding: 0;
    margin-bottom: 2rem;

    @include tablet() {
      margin-bottom: 1rem;
    }
  }

  &.FullWidthOnTablet {
    width: unset;

    @include tablet() {
      width: 100%;
    }
  }

  &.FlexRowToColumnOnBigTablet {
    @include bigTablet() {
      flex-direction: column;
    }
  }

  &.borderRight {
    border-right: 1px solid var(--default_black);

    padding: 0;
    padding-right: 1rem;
  }

  &.borderRadius {
    border-radius: $row_border_radius;
  }
  &.smallPadding {
    padding: 8px;
  }

  &.isHoverableOnWhite {
    &:hover {
      background-color: var(--layout_content_background);
    }
  }

  &.isHoverableOnWhite_error {
    &:hover {
      background-color: var(--error_background);
    }
  }

  &.hideOnBigTablet {
    @include bigTablet() {
      display: none;
    }
  }
}

.WrapperFlexCol {
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  gap: $parent_gap;

  padding: $parent_padding;

  @include tablet() {
    gap: $tablet_gap;
    padding: $tablet_padding;
  }

  &.isHoverable {
    border: 2px solid transparent;
    border-radius: $box_border_radius;

    &:hover {
      cursor: pointer;
      border: 2px solid var(--primary_color);
    }

    &.isActive {
      &:hover {
        border: 2px solid var(--light_primary_hover);
      }
    }
  }

  &.borderRadius {
    border-radius: $box_border_radius;
  }

  &.hideOnTablet {
    @include tablet() {
      display: none;
    }
  }

  &.layoutBg {
    background-color: var(--layout_content_background);
  }

  &.isActive {
    border: 2px solid var(--light_primary_hover);
  }

  &.isActiveBg {
    background-color: var(--primary_color);

    p {
      color: var(--white);
    }

    span {
      color: var(--white);
    }
  }
}

.DashboardErrorContainer {
  width: 100%;

  padding: 4rem 1rem;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  gap: 1rem;

  @include mobile() {
    padding: 2rem 0;
  }

  border-radius: $box_border_radius;
  // border: 1px solid var(--box_border_color);

  background-color: var(--box_default_bg);
  box-shadow: var(--box_box_shadow);

  &.removeBoxShadow {
    box-shadow: unset;
  }

  &.onDefaultWhite {
    background-color: var(--layout_content_background);
  }

  &.layoutBg {
    background-color: var(--box_on_default_bg);
  }

  &.smallerBorderRadius {
    border-radius: calc($box_border_radius / 2);
  }
}

.TwoActionButtonsDangerWrapper,
.TwoActionButtonsWrapper {
  margin-top: 0.75rem;
}

.TwoActionButtonsWrapper {
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: flex-end;

  gap: $parent_gap;

  @include tablet() {
    flex-wrap: wrap;
  }
}

.TwoActionButtonsDangerWrapper {
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: flex-end;

  gap: $parent_gap;

  @include tablet() {
    flex-wrap: wrap;
  }
}

.SectionIconWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  width: 50px;
  height: 50px;

  border-radius: $row_border_radius;
  // border: 1px solid var(--box_border_color);
  background-color: var(--layout_content_background);

  svg {
    width: 26px;
    height: 26px;
  }

  @include tablet() {
    width: 40px;
    height: 40px;

    svg {
      width: 20px;
      height: 20px;
    }
  }
}

.ImageSelectorWrapper {
  .SelectImageContainer {
    width: 100%;
    height: 75px;

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: $parent_gap;

    .SelectedImageContainer {
      width: 80px;
      height: 80px;

      border-radius: 50px;
      padding: 1rem;

      background-color: var(--layout_content_background);

      box-shadow: var(--animal_image_shadow);

      display: flex;
      align-items: center;
      justify-content: center;

      img {
        min-width: 50px;
      }
    }

    .ImagesGallery {
      width: 100%;
      height: calc(80px);
      display: grid;
      overflow-y: scroll;

      grid-template-columns: repeat(
        auto-fill,
        minmax(calc(40px - 0.25rem), 1fr)
      );
      grid-template-rows: auto;

      gap: $select_image_gap;

      img {
        width: calc(40px - 0.25rem);
        height: auto;
        border-radius: calc($row_border_radius * 0.5);
        padding: $image_padding;
        cursor: pointer;

        &:hover {
          background-color: var(--dashboard_action_bg);
        }
      }

      .ImagePicked {
        background-color: var(--dashboard_action_bg);
      }
    }
  }

  &.UserAvatarSelectorWrapper {
    border-radius: $row_border_radius;

    .SelectedImageContainer {
      border-radius: $row_border_radius;
    }
  }
}

.SingleRowDisplay {
  width: 100%;

  background-color: var(--box_default_bg);
  box-shadow: var(--box_box_shadow);

  border-radius: $box_border_radius;
  // border: 1px solid var(--box_border_color);

  padding: $parent_padding;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: $parent_gap;

  overflow: hidden;

  @include tablet() {
    gap: $tablet_gap;
    padding: $tablet_padding;
  }

  // transition: all 200ms;

  // &:hover {
  //     box-shadow: $box_default_hover_shadow;
  //     transform: scale(1.01);
  // }
}

.SingleRowLeft {
  display: flex;
  gap: 1rem;
  align-items: center;
  overflow: hidden;
}

.SingleRowRight {
  height: 100%;
  min-width: min-content;

  display: flex;
  gap: $parent_gap;
  align-items: center;
  overflow: hidden;
}

.RowIcon {
  background-color: var(--box_default_bg);
  display: flex;
  align-items: center;
  justify-content: center;

  max-height: 50px;
  min-height: 50px;

  min-width: 50px;
  max-width: 50px;

  box-shadow: var(--animal_image_shadow);

  border-radius: $table_icon_radius;

  padding: $image_padding;

  img {
    max-height: $table_icon_height;
    min-height: $table_icon_height;

    min-width: $table_icon_width;
    max-width: $table_icon_width;
  }
}

.TwoElementsInColumn {
  display: flex;
  flex-direction: column;

  overflow-x: hidden;
}

.SectionTabsContainer {
  width: 100%;

  display: flex;
  align-items: center;

  margin-top: 1rem;

  margin-bottom: calc(-0.5rem - 2px);

  button {
    background-color: var(--layout_content_background);
  }

  .TabText {
    color: var(--default_black);

    padding: 0.75rem 1rem;

    cursor: pointer;

    user-select: none;

    font-size: $default_font_small_size;
    font-weight: $bold_font_weight;

    z-index: 10;

    border: 0;
    border-bottom: 2px solid var(--filter_hr_bg);

    transition: all 200ms;

    &:hover {
      border-color: var(--primary_color);
      color: var(--primary_color);
    }

    &:first-child {
      padding-left: 0.25rem;
    }

    &:last-child {
      padding-right: 0.25rem;
    }
  }

  .FilterCurrent {
    border-color: var(--primary_color);
    color: var(--primary_color);
  }

  .FilterLocked {
    opacity: 0.6;
  }
}

.SectionTabsContainer_Hr {
  height: 0.5px;
  border-radius: $row_border_radius;
  background-color: var(--filter_hr_bg);
  width: 100%;
  position: relative;
  z-index: 0;
  margin-top: 0.25px;
  margin-bottom: 0.5rem;
}

.ButtonSelectBetween {
  display: flex;

  padding: 4px;

  gap: 4px;

  background-color: var(--on_layout_hover_background);
  border-radius: 4px;

  button {
    flex: 1;
    background-color: var(--default_white);

    border-radius: 4px;

    transition: all 0.2s;
    // border: 1px solid var(--box_border_color);
  }
}

.LayoutIcon {
  display: flex;
  align-items: center;
  justify-content: center;

  background-color: var(--layout_content_background);

  border-radius: $button_border_radius;

  padding: 0.25rem;

  cursor: pointer;

  &:hover {
    background-color: var(--box_default_bg);
  }
}

.CurrentLayout {
  background-color: var(--box_default_bg);
}

.MySelect {
  appearance: auto;
  -webkit-appearance: none;

  // border: 1px solid var(--box_border_color);
  border-radius: $input_default_border_radius;

  background-color: var(--input_default_bg);
  color: var(--input_default_color);

  font-size: $default_font_size;
  font-weight: $input_font_weight;

  padding: $input_default_padding;
  // padding-top: 0;
  // padding-bottom: 0;

  @include tablet() {
    font-size: $default_font_small_size;
  }

  &:focus {
    border-color: var(--primary_color);
  }

  &:focus-visible {
    border-color: var(--primary_color);
  }

  option {
    font-size: $default_font_size;

    background-color: var(--input_default_bg);
    color: var(--input_default_color);

    @include tablet() {
      font-size: $default_font_small_size;
    }
  }

  &.CategorySelect {
    font-size: $default_font_small_size;
    height: $input_height;
    // border: 1px solid var(--box_border_color);

    padding-top: 0;
    padding-bottom: 0;

    option {
      font-size: $default_font_small_size;
    }
  }

  &:disabled {
    opacity: 0.5;
    cursor: unset;
  }

  &.fixedHeight {
    padding: 0 0.75rem;
    height: $input_height;
  }

  &.inputHeight {
    min-height: $input_height;
  }
}

.DashboardColorPicker {
  width: 100%;
  max-height: 80px;

  display: flex;
  gap: 0.5rem;
  flex-wrap: wrap;

  padding: 0.25rem 0.25rem;

  overflow: scroll;
  // Hide scrollbar
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;

  /* Firefox */
  &::-webkit-scrollbar {
    display: none;
  }

  div {
    min-width: 26px;
    width: 26px;
    max-width: 26px;

    min-height: 18px;
    height: 18px;
    max-height: 18px;

    border-radius: 4px;

    cursor: pointer;

    transition: all 200ms;

    display: inline-block;

    &:hover {
      transform: scale(1.2);
    }
  }

  .PickedColor {
    transform: scale(1.2);
  }
}

.ProgressBar {
  width: 100%;
  height: 16px;

  // background-color: #111;

  display: flex;
  flex-direction: row;
  justify-content: space-between;

  gap: 1rem;

  .ProgresBarWrapper {
    width: 100%;
    height: 100%;

    max-width: 70%;

    border-radius: 50px;
    overflow: hidden;

    background-color: var(--layout_content_background);

    .ProgressBarBar {
      width: 100%;
      height: 100%;

      border-radius: 50px;

      background: var(--primary_gradient);
    }
  }

  .ProgresBarFull {
    .ProgressBarBar {
      background: var(--error_gradient);
    }
  }

  .ProgresBarInfo {
    max-width: 100px;
    padding: 0;
    justify-content: space-between;
  }

  p {
    min-width: max-content;
    text-align: left;
  }
}

.DropdownContent {
  display: none;
  position: absolute;
  z-index: 100;
  width: 100%;

  backdrop-filter: blur(14px);

  &.show {
    display: flex;
    flex-direction: column;
    gap: 4px;

    min-height: 50px;

    min-width: 200px;
    width: max-content;

    top: calc($hero_navbar_height - 12px);

    border-radius: calc($box_border_radius - 6px);

    // background-color: var(--box_default_bg);
    box-shadow: var(--dropdown_shadow);
    background-color: var(--navbar_sidebar_background);

    padding: 1rem;

    @include bigTablet() {
      display: none;
    }
  }

  &.stickLeft {
    left: 0;
  }

  &.stickRight {
    right: 0;
  }
}

.RoundedProgresBar {
  width: 80px;
  height: 80px;

  display: flex;
  align-items: center;
  justify-content: center;

  border-radius: 50%;
}
