@import "../../../../styles/variables.scss";
@import "../../../../styles/mixins.scss";
@import "../../../../styles/text.scss";

$calendar_border_radius: 4px;
$calendar_user_avatar_width: 24px;

// For Calendar Month
$calendar_month_gap: 4px;

$calendar_header_height: 50px;

$row_min_width: 220px;

$row_min_height_mobile: 200px;

// Visit Edit / Delete button
.VisitEditWrapper {
  padding: 0.1rem;

  border-radius: $calendar_border_radius;

  display: flex;
  align-items: flex-end;
  justify-content: flex-end;

  svg {
    width: 16px;
    height: 16px;
  }

  cursor: pointer;

  &:hover {
    background-color: var(--on_layout_hover_background);
  }
}

.MuTimeWrapper {
  min-height: $input_height;

  flex: 1;

  div {
    padding-top: 0;
    padding-bottom: 0;
    min-height: $input_height;

    border-radius: $input_default_border_radius;

    input {
      padding: 0;
    }

    div > div {
      padding-right: 0;

      padding: 0.25rem;

      border: 1px solid transparent;
    }

    div > div > div {
      min-width: min-content;
      svg {
        fill: var(--icons_stroke_color);
      }
    }
  }
}

.AddVisitBtnAddClient {
  cursor: pointer;
  gap: 0.5rem;
  max-width: unset;
  width: max-content;
  padding: 0.25rem 0.5rem;
  height: unset;
  margin: 0.25rem 0;
  font-size: 14px;
  color: var(--default_black);

  display: flex;
  align-items: center;
  border-radius: 4px;

  background-color: var(--layout_content_background);

  &:hover {
    background-color: var(--on_layout_hover_background);
  }

  &.disabled {
    cursor: default;
    opacity: 0.5;

    &:hover {
      background-color: var(--layout_content_background);
    }
  }
}

$AddVisitBtnSize: 50px;
$AddVisitBtnSize_Tablet: 36px;

.Calendar {
  display: flex; // Use Flexbox
  flex-direction: column; // Stack children vertically
  gap: 1rem;
  height: max-content;

  .LoadingCalendarOverlay {
    width: calc(100vw - $dashboard_side_navbar_width);
    height: calc(100vh - $dashboard_navbar_height);

    background-color: var(--dashboard_page_blur);

    position: fixed;

    top: calc($dashboard_navbar_height);
    left: calc($dashboard_side_navbar_width);

    border-top-left-radius: 16px;

    z-index: 220;

    display: flex;
    align-items: center;
    justify-content: center;

    @include mobile() {
      width: 100vw;
      left: 0;
    }

    .LoadingCalendarBox {
      min-height: unset;
      max-height: 200px;
      height: 200px;

      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .RightBottomControlls {
    width: min-content;
    height: unset;
    min-height: 50px;

    padding: 0.25rem;

    position: fixed;
    bottom: 3rem;
    right: 3rem;

    z-index: 200;

    display: flex;
    justify-content: flex-end;
    align-items: center;

    box-shadow: var(--box_box_shadow);
    border-radius: $box_border_radius;

    @include tablet() {
      padding: 0.25rem 0.5rem;
      bottom: 1rem;

      right: 1rem;
    }

    @include mobile() {
      padding: 0.25rem 0.5rem;
      bottom: calc($dashboard_navbar_mobile_height + 1rem);

      right: 1rem;
    }

    .AddVisitBtn,
    .ScrollToCurrentTime {
      min-width: $AddVisitBtnSize;
      min-height: $AddVisitBtnSize;

      max-width: $AddVisitBtnSize;
      max-height: $AddVisitBtnSize;

      padding: 0;

      border-radius: 50px;

      box-shadow: var(--box_box_shadow);

      @include tablet() {
        min-width: $AddVisitBtnSize_Tablet;
        min-height: $AddVisitBtnSize_Tablet;

        max-width: $AddVisitBtnSize_Tablet;
        max-height: $AddVisitBtnSize_Tablet;
      }
    }

    .ScrollToCurrentTime {
      border-radius: $button_border_radius;
    }
  }

  .CalendarWrapper {
    display: flex;
    flex-direction: row;
    gap: 1rem;

    // We are decreasing it by layout padding and SPACE that takes title section
    height: calc(100svh - $dashboard_navbar_height - 2rem - 64px - 1rem);

    @include tablet() {
      height: max-content;
      flex-direction: column;

      // extra space on mobile for latest row of visits
      // padding-bottom: 4rem;
    }

    .CalendarScrollToCurrentTime {
      width: 40px;
      height: 40px;

      position: fixed;

      background-color: var(--primary_color);

      border-radius: $button_border_radius;

      bottom: 3rem;
      right: 3rem;

      z-index: 99;

      display: flex;
      align-items: center;
      justify-content: center;

      padding: 0.35rem;

      svg {
        width: 32px;
        height: 32px;
      }

      cursor: pointer;

      &:hover {
        background-color: var(--on_primary_hover);
      }

      @include mobile {
        display: none;
      }
    }

    .CalendarLeft {
      min-width: 236px;
      max-width: 236px;

      gap: 1rem;

      overflow-y: scroll;

      padding: 0;

      @include tablet() {
        max-width: unset;
      }

      .CalendarMini {
        width: 100%;
        min-height: max-content;
        padding: 0;

        .CalendarMiniWrapper {
          padding: $parent_padding;

          display: flex;
          flex-direction: column;
          gap: 0.5rem;

          // border: 1px solid var(--box_border_color);
          // box-shadow: var(--box_box_shadow);
          border-radius: calc($row_border_radius * 0.5);

          .CalendarMiniActions {
            width: 100%;

            display: flex;
            align-items: center;
            justify-content: space-between;

            button {
              padding: 0.25rem;

              flex-direction: row;
              align-items: center;
              justify-content: center;

              svg {
                margin: 0;

                polyline {
                  stroke-width: 1.5px;
                }
              }
            }
          }

          .CalendarMiniDaysNames {
            width: 100%;

            display: grid;
            grid-template-columns: repeat(7, 1fr); // 7 columns

            gap: 0.15rem;

            p {
              width: 24px;
              text-align: center;
            }
          }

          .CalendarMiniDays {
            width: 100%;

            display: grid;
            grid-template-columns: repeat(7, 1fr); // 7 columns
            grid-auto-rows: 1.5rem;
            gap: 0.15rem;

            .CalendarDay {
              width: 24px;
              height: 24px;

              display: flex;
              align-items: center;
              justify-content: center;

              cursor: pointer;

              user-select: none;

              border-radius: calc($button_border_radius * 0.5);

              &:hover {
                background-color: var(--icons_background);
              }
            }

            .currentDay {
              background-color: var(--primary_color);
              color: var(--white);
              cursor: unset;

              &:hover {
                background-color: var(--on_primary_hover);
              }
            }

            .todayDay {
              border: 1px solid var(--box_border_color);

              &.currentDay {
                border: unset;
                cursor: unset;
              }
            }
          }
        }
      }

      .CalendarUsers {
        width: 100%;
        min-height: max-content;
        padding: 1rem;

        .CalendarUsersWrapper {
          width: 100%;

          display: flex;
          flex-direction: column;
          gap: 1rem;
        }

        img {
          max-width: $calendar_user_avatar_width;
        }

        .CalendarUserAvaliableDayTag {
          width: max-content;

          padding: 0.25rem 0.5rem;
          border-radius: calc($row_border_radius);
          // border: 1px solid var(--box_border_color);
          background-color: var(--icons_background);
        }
      }

      .PriceListBox {
        padding: 1rem;
        min-height: max-content;

        .PriceListNavigateTo {
          cursor: pointer;

          display: flex;
          align-items: center;
          justify-content: center;

          padding: 0.25rem;
          border-radius: calc($row_border_radius);

          &:hover {
            background-color: var(--on_layout_hover_background);
          }
        }
      }

      .CalendarWorkdays {
        width: 100%;
        padding: 1rem;
        min-height: max-content;
        // border: 1px solid var(--box_border_color);
      }
    }

    .CalendarRight {
      width: 100%;
      background-color: var(--layout_content_background);
      // border: 1px solid var(--box_border_color);
      // box-shadow: var(--box_box_shadow);
      border-radius: $box_border_radius;
      // border-top-right-radius: 0;
      // border-bottom-right-radius: 0;
      align-items: flex-start;
      /* Scroll for entire area */
      gap: 0rem;
      overflow-x: auto;

      position: relative;

      @include tablet() {
        min-height: 400px;
        max-height: 70svh;
      }

      .CalendarRightUsersDescription {
        width: 100%;
        min-height: $calendar_header_height;
        height: $calendar_header_height;

        padding: 0;
        position: sticky;

        top: 0;

        display: flex;

        z-index: 50;

        background-color: var(--box_default_bg);

        .UserDescription {
          width: calc(300px + 1px);
          min-width: calc(300px + 1px);
          flex: 1;

          display: flex;
          align-items: center;
          justify-content: center;

          // Keep this to have equal sizes between userDescription and calendar column
          border-left: 1px solid var(--box_border_color);
          border-bottom: 1px solid var(--box_border_color);

          background-color: var(--calendar_header_bg);
          backdrop-filter: blur($sticky_top_blur);

          &:first-child {
            border-left: unset;
          }

          img {
            max-width: 24px;
          }

          // .UserDescriptionText {
          //   // color: var(--default_white);
          // }
        }
      }

      .CalendarRightColumns {
        flex: 1;
        min-width: max-content;
        padding: 0;
        height: max-content;
        align-items: flex-start;
        gap: 1px; // This has to be the same as border-left in UserDescription

        background-color: var(--box_border_color);

        position: relative;

        .CalendarCurrentTimePointer {
          width: 100%;
          height: 2px;

          position: absolute;

          z-index: 5;

          border-bottom: 2px dotted var(--primary_color);

          .CalendarCurrentTimePointer_Time {
            position: relative;

            width: 50px;
            min-width: max-content;
            height: unset;
            min-height: max-content;
            height: 20px;

            background-color: var(--primary_color);

            // +2px is so we will hide this border-bottom 2px from .CalendarCurrentTimePointer
            top: calc(-20px + 2px);
            border-top-left-radius: $calendar_border_radius;
            border-top-right-radius: $calendar_border_radius;

            text-align: center;

            padding: 0.1rem 0.25rem;
          }
        }

        .CalendarUserVisitsColumn {
          width: 300px;
          min-width: 300px;
          flex: 1;

          display: flex;
          flex-direction: column;

          overflow-x: auto;
          overflow-y: hidden;

          position: relative;

          .AddVisitBtn_Container {
            display: flex;
            position: absolute;
            height: calc(64px);

            width: max-content;
            min-width: 100px;
            right: 0;

            // This margin top has to be decresed by .CalendarVisitRow padding
            margin-top: -0.5rem;
            margin-left: -0.5rem;

            background-color: transparent;

            .AddVisitBtn_Day {
              position: absolute;
              right: 0.25rem;
              top: 0.25rem;

              display: none;

              padding: 0.25rem 0.5rem;
            }

            &:hover {
              .AddVisitBtn_Day {
                background-color: var(--button_neutral_bg);
                color: var(--white);

                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: center;

                cursor: pointer;

                &:hover {
                  background-color: var(--button_neutral_bg_hover);
                }

                z-index: 30;
              }
            }
          }

          .CalendarVisitRow {
            height: calc(64px);
            max-width: inherit;

            border-top: 1px solid var(--box_border_color);

            background-color: var(--box_default_bg);

            display: flex;

            padding: 0.5rem;
            gap: 0.5rem;

            &:nth-child(1) {
              border: unset;
            }

            .VisitBox {
              min-width: 100px;
              max-width: calc((100% / 2) - 0.25rem);

              flex: 1;
              /* Add position relative here */
              position: relative;

              background-color: var(--box_default_bg);
              color: #222;

              transition: all 100ms;

              overflow-y: auto;

              // box-shadow: -1px 2px 3px #ccc;
              border-radius: 4px;
              // border: 1px solid var(--box_border_color);
              box-shadow: var(--box_box_shadow);

              &:hover {
                z-index: 10;
                /* Ensure this is high enough */
                opacity: 1;
                /* Keep position: relative; here for clarity, even though it's redundant */
                transform: scale(1.01);
              }

              // When we have more than 2 visis, this style is ON
              &.TooManyVisits {
                min-width: unset;
              }

              .VisitBoxContent {
                height: 100%;
                padding: 0.75rem;

                .visitText {
                  white-space: nowrap;
                  overflow: hidden;
                  word-break: break-all;
                  line-break: loose;
                }

                .VisitAcceptBox {
                  display: flex;

                  // Visit checkbox
                  input {
                    width: 12px;
                    height: 12px;
                    appearance: auto;
                  }
                }

                .VisitEditWrapper {
                  svg {
                    width: 1rem;
                    height: 1rem;
                  }
                }

                &.OneRowVisit {
                  display: flex;
                  align-items: center;
                  justify-content: space-between;
                  flex-direction: row;

                  padding: 0.3rem 0.75rem;

                  background-color: var(--default_white);
                }

                &.TwoRowVisit {
                  display: flex;
                  align-items: flex-start;
                  justify-content: space-between;
                  flex-direction: row;
                  flex-wrap: wrap;

                  padding: 0.5rem 0.75rem;

                  background-color: var(--default_white);
                }

                &.ThreeRowPlusVisit {
                  display: flex;
                  align-items: flex-start;
                  justify-content: space-between;
                  flex-direction: row;
                  flex-wrap: wrap;

                  background-color: var(--default_white);
                }
              }
            }
          }

          // .CalendarVisitRowMargin {
          //     background-color: #f6f6f6;
          // }
        }
      }

      .CalendarTimeColumn {
        width: 100px;
        min-width: 50px;
        max-width: 50px;
        padding: 0;
        height: max-content;

        display: flex;
        flex-direction: column;
        gap: 0rem;

        .CalendarTimeBox {
          width: 100%;
          height: calc(64px);

          border-top: 1px solid var(--box_border_color);

          &:nth-child(1) {
            border: unset;
          }

          background-color: var(--default_white);
          background-color: var(--calendar_today_box);

          padding: 0rem;
          padding-top: 0.15rem;

          display: flex;
          align-items: flex-start;
          justify-content: center;
        }
      }
    }
  }

  .CalendarNav {
    position: sticky;
    top: calc($dashboard_navbar_height);

    z-index: 140;

    background-color: var(--layout_content_background);
    // box-shadow: var(--box_box_shadow);
    border-radius: 0px;

    .CalendarControls {
      // background-color: var(--sticky_top_bg);
      // backdrop-filter: blur($sticky_top_blur);
      background-color: transparent;

      padding: 0.5rem;

      display: flex;
      align-items: center;
      justify-content: center;

      button {
        padding: 0.4rem;
      }

      @include tablet() {
        .btnTransform {
          transform: rotate(90deg);
        }
      }

      @include mobile() {
        padding: 0.4rem 0.25rem;

        button {
          padding: 0.2rem;
        }
      }
    }
  }

  .CalendarActions {
    width: 100%;

    // background-color: pink;
    background-color: var(--layout_content_background);
    // background-color: pink;

    padding: 0.5rem 0;
    gap: 0.5rem;

    position: sticky;
    top: $dashboard_navbar_height;

    justify-content: space-between;

    overflow-x: hidden;

    min-height: min-content;
    height: 64px;
    max-height: max-content;

    @include mobile() {
      flex-direction: column-reverse;
      align-items: flex-start;
      justify-content: center;

      .CalendarActionsRight {
        align-items: center;
        justify-content: center;
      }
    }

    .CalendarActionsLeft {
      // max-width: 236px;
      // height: 100%;
      // padding: 0;
      // gap: 0.5rem;

      button {
        padding: 0.25rem;
        min-height: 30px;
        max-height: 30px;
        width: 30px;
      }

      .CalendarDateText {
        width: 86px;
        text-align: center;
      }

      // This rotates icon 90deg so it looks correct on Tablet / mobile
      .RotateIconOnTablet {
        svg {
          width: 16px;
          height: 16px;
          margin: 0;
        }

        @include mobile {
          svg {
            transform: rotate(90deg);
          }
        }
      }
    }

    .CalendarActionsRight {
      padding: 0rem;
      height: 100%;

      gap: 0.5rem;

      div {
        justify-content: flex-end;
      }

      @include mobile() {
        div {
          justify-content: space-between;
        }
      }

      button {
        padding: 0.35rem;
        min-height: 24px;
        max-height: 24px;

        display: flex;
        align-items: center;

        svg {
          width: 24px;
          height: 24px;
        }
      }

      .CurrentTimeSpanWrapper {
        display: flex;
        gap: 0.25rem;
      }
    }
  }
}

.CalendarMonth {
  width: 100%;
  height: 100%;

  overflow-x: auto;

  border-radius: $box_border_radius;
  // box-shadow: var(--box_box_shadow);

  background-color: var(--box_default_bg);

  @include tablet() {
    min-height: 400px;
    max-height: calc(
      100svh - $dashboard_navbar_height - $dashboard_navbar_mobile_height -
        100px
    );

    padding-bottom: 0.5rem;
  }

  .CalendarMonthHeader {
    // min-width: max-content;
    height: $calendar_header_height;
    min-height: $calendar_header_height;

    display: grid;
    grid-template-columns: repeat(7, 1fr);

    background-color: var(--calendar_header_bg);
    backdrop-filter: blur($sticky_top_blur);
    border-bottom: 1px solid var(--box_border_color);

    position: sticky;
    top: 0;

    align-items: center;

    .CalendarMonthWrapper {
      width: 100%;
      height: 100%;

      min-width: $row_min_width;

      padding: 0.5rem;

      border-right: 1px solid var(--box_border_color);

      background-color: rgba(var(--primary_color), 0.75);

      display: flex;
      align-items: center;
      justify-content: center;

      p {
        text-align: center;
        // color: var(--default_white);
      }

      &:last-child {
        border: unset;
      }
    }
  }

  .CalendarDaysRowWrapper {
    width: 100%;
    height: calc(100%);

    display: flex;
    flex-direction: column;
    min-width: min-content;

    .CalendarDaysRow {
      width: 100%;
      flex: 1;

      // Be careful, this seems like proper setting
      // But its a bit random
      max-height: calc(30%);
      min-height: calc(30%);

      // min-width: max-content;

      border-top: 1px solid var(--box_border_color);

      &:nth-child(2) {
        border: none;
      }

      display: flex;
      flex-direction: row;

      .CalendarVisitsBox {
        flex: 1;
        border-right: 1px solid var(--box_border_color);

        // background-color: #fbfbfb;

        min-width: $row_min_width;
        height: 100%;

        display: flex;
        flex-direction: column;

        @include tablet() {
          max-height: $row_min_height_mobile;
          min-height: $row_min_height_mobile;
        }

        // This is needed, to be transparent
        // It makes our header and content in calendar
        // equal in vertical line
        &:nth-child(7n) {
          border-right: 1px solid transparent;
        }

        &.lastRowBorderBottom {
          border-bottom: 1px solid var(--box_border_color);
        }

        &:hover {
          .currentDayHeader {
            .AddVisitBtn {
              cursor: pointer;

              background-color: var(--primary_color);
              color: var(--white);
              width: min-content;
              padding: 0 0.25rem;
              height: 14px;

              border-radius: calc($row_border_radius);
              display: flex;
              align-items: center;
              justify-content: center;

              &:hover {
                background-color: var(--on_primary_hover);
              }
            }
            .SwitchToDay {
              display: block;
              cursor: pointer;

              &:hover {
                text-decoration: underline;
              }
            }
          }
        }

        .currentDayHeader {
          width: 100%;
          min-height: 32px;
          max-height: 32px;

          display: flex;
          flex-direction: row;

          justify-content: space-between;
          align-items: center;

          padding: 0 0.5rem;

          .SwitchToDay {
            display: none;
          }

          .AddVisitBtn {
            display: none;
            min-height: 18px;
          }

          .howManyVisits {
            width: 24px;
            height: 18px;

            color: var(--white);

            display: flex;
            flex-direction: row;

            justify-content: center;
            align-items: center;

            border-radius: calc($row_border_radius * 0.25);
          }
        }

        .CalendarVisitWrapper {
          width: 100%;
          height: 100%;

          overflow-y: scroll;
          overflow-x: hidden;

          display: flex;
          flex-direction: column;
          gap: 1rem;

          padding: 0.5rem;
          padding-top: 0.5rem;
          padding-bottom: 1.5rem;

          .CalendarVisit {
            width: 100%;

            padding: 0.5rem 0.75rem;

            // box-shadow: -1px 2px 3px #ccc;
            border-radius: 4px;
            // border: 1px solid var(--box_border_color);
            box-shadow: var(--box_box_shadow);

            background-color: var(--default_white);

            .VisitToggleBox {
              width: fit-content;

              input {
                width: 12px;
                height: 12px;
                appearance: auto;
              }
            }

            .CalendarMonthUserAvatar {
              max-width: 14px;
            }
          }
        }

        &.currentDayHeaderIsToday {
          background-color: var(--calendar_today_box);

          .currentDayHeader {
            // background-color: rgba(var(--primary_color), 0.75);
            // color: var(--default_white);
          }
        }
      }
    }
  }
}

.VisitToggleBox {
  width: fit-content;

  input {
    width: 12px;
    height: 12px;
    appearance: auto;
  }
}

.RemoveServiceAssingIcon {
  width: 14px;
  height: 14px;
  min-width: 14px;
  min-height: 14px;

  cursor: pointer;

  path {
    stroke: var(--icons_stroke_color);
  }

  &:hover {
    path {
      stroke: var(--error_color);
    }
  }
}

.PriceListSelect {
  .PriceListBox {
    padding: 0.5rem;
    cursor: pointer;

    &:hover {
      background-color: var(--on_layout_hover_background);
    }

    .FavPriceListIcon {
      width: 0.75rem;
      height: 0.75rem;

      path {
        stroke: var(--default_text_color_gray);
      }
    }
  }

  .SelectedPriceList {
    background-color: var(--layout_content_background);

    &:hover {
      background-color: var(--layout_content_background);
    }
  }
}
