@import "../../../styles/mixins.scss";
@import "../../../styles/variables.scss";
@import "../../../styles/text.scss";

.ButtonCancel,
.ButtonSave,
.ButtonDelete {
    min-width: min-content;
    max-height: 45px;


    display: flex;
    align-items: center;
    justify-content: center;

    border-radius: $button_border_radius;
    background-color: var(--layout_content_background);

    padding: $button_default_padding;

    border: 0;
    font-size: $default_font_size;

    color: var(--button_neutral_color);

    white-space: nowrap;

    user-select: none;

    // border: 1px solid var(--box_border_color);

    cursor: pointer;

    svg {
        width: $svg_medium_width;
        height: $svg_medium_height;
    }

    @include tablet() {
        max-width: max-content;

        padding: $button_small_padding;

        font-size: $default_font_small_size;

        svg {
            width: $svg_small_width;
            height: $svg_small_height;
        }
    }



    &[disabled] {
        opacity: 0.5;
        cursor: unset;

        // &:hover {
        //     color: var(--button_neutral_color);
        //     background-color: var(--dashboard_action_bg);
        // }
    }
}

.ButtonCancel {
    &:not([disabled]):hover {
        color: var(--white);
        background-color: var(--button_error_bg);
    }
}

.ButtonSave {
    &:not([disabled]):hover {
        background-color: var(--primary_color);
        color: var(--white);

        svg {
            path {

                stroke: var(--white);
            }

            line {
                stroke: var(--white);
            }

            polyline {
                stroke: var(--white);
            }
        }
    }


}

.ButtonDelete {
    &:not([disabled]):hover {
        color: var(--white);
        background-color: var(--button_error_bg);
        // border: 1px solid var(--error_color);

        svg {
            path {
                stroke: var(--white);
            }
        }
    }
}

.HeartToggle {
    display: flex;
    align-items: center;

    .ClientFavHeart {
        display: flex;
        align-items: center;
        justify-content: center;

        border-radius: $button_fav_radius;

        padding: 0.25rem;

        cursor: pointer;

        svg {
            width: $svg_medium_width;
            height: $svg_medium_height;
        }
    }

    .ClientFavHeart {
        cursor: pointer;

        &:hover {
            background-color: var(--layout_content_background);
        }
    }

    .ClientIsFav {
        svg {
            path {
                fill: var(--error_background);
                stroke: var(--error_color);
            }
        }
    }

    .FavLocked {
        cursor: unset;

        &:hover {
            svg {
                path {
                    stroke: unset;
                    fill: unset;
                }
            }
        }
    }
}