@import "../../../styles/variables.scss";
@import "../../../styles/mixins.scss";

.Navbar {
    width: 100%;
    height: $dashboard_navbar_height;

    background-color: var(--navbar_sidebar_background);

    user-select: none;

    position: fixed;
    top: 0;

    z-index: 999;

    // box-shadow: 0px 2px 8px #ccc;

    .NavbarWrapper {
        width: 100%;
        height: 100%;

        display: flex;

        .NavbarLeft {
            height: 100%;
            flex: 1;

            display: flex;

            .NavbarLogo {

                cursor: pointer;

                width: $dashboard_side_navbar_width;

                display: flex;
                justify-content: center;
                align-items: center;

                svg {
                    min-width: 36px;
                    min-height: 36px;

                    width: 44px;
                    height: 44px;
                }

            }


            .NavbarBurgerWrapper {
                width: $dashboard_side_navbar_width;
                height: 100%;

                display: flex;
                align-items: center;
                justify-content: center;



            }

            .NavbarLogoWrapper {
                height: 100%;

                display: flex;
                align-items: center;

                a {
                    display: flex;
                    align-items: center;

                    .NavbarLogo {
                        min-width: 150px;
                        max-width: 150px;

                    }
                }


            }

            .NavbarCurrentTable {

                display: flex;
                align-items: center;
                overflow-x: hidden;
                white-space: nowrap;

                gap: 1rem;

                .NavbarBurger {

                    padding: 0.75rem;

                    display: flex;
                    align-items: center;
                    justify-content: center;

                    border-radius: 10px;

                    // background-color: var(--icons_background);

                    svg {
                        path, circle {
                            stroke: var(--default_black);
                        }
                    }

                    &:hover {
                        background-color: var(--icons_background_hover);
                        cursor: pointer;
                    }
                }

            }
        }

        .NavbarRight {
            flex: 1;
            height: 100%;

            .NavbarRightContainer {
                width: 100%;
                height: 100%;

                display: flex;
                align-items: center;
                justify-content: flex-end;
                gap: 0.5rem;

                padding: 0 1rem;

                .NavbarActionContainer {
                    padding: 0.75rem;

                    display: flex;
                    align-items: center;
                    justify-content: center;
                    gap: 0.5rem;

                    border-radius: 10px;

                    svg {
                        width: 24px;
                        height: 24px;

                        path, circle {
                            stroke: var(--default_black);
                        }
                    }

                    &:hover {
                        background-color: var(--icons_background_hover);
                        cursor: pointer;
                    }

                    // Hide Exit on tablet (and show it in sidebar)
                    @include tablet {
                        &:nth-child(3) {
                            display: none;
                        }
                    }

                    // Hide User on mobile (and show it in sidebar)
                    @include mobile {
                        &:nth-child(1) {
                            display: none;
                        }
                    }

                    &.NavbarActionUser {
                        padding: 0 0.5rem;

                        img {
                            max-width: 20px;
                            max-height: 20px;
                        }

                        &:hover {
                            background-color: transparent;
                            cursor: unset;
                        }
                    }


                }
            }
        }
    }
}